import { Model_createAssetGroup } from "../db/dbModels"
import { Box, 
    Dialog, DialogActions, DialogContent, DialogTitle, 
    Button, Divider, TextField, IconButton,
    Accordion, AccordionDetails, AccordionSummary, Typography,
    } from "@mui/material"

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useState } from "react";
import AssetEyeCatches from './assetEyeCatches';

type CreateAssetGroupDialogProps = {
    open: boolean,
    onClose: () => void,
}

const CreateAssetGroupDialog = (props : CreateAssetGroupDialogProps)  => {

    const [eyeCatchUrl, setEyeCatchUrl] = useState<string>('')
    const [assetGroupName, setAssetGroupName] = useState<string>('')
    const [errorMessage, setErrorMessage] = useState<string>('')

    const handleOnClose = () => {
        setEyeCatchUrl('')
        setAssetGroupName('')
        setErrorMessage('')
        props.onClose()
    }

    const handleAddAssetGroup = () => {
        if (assetGroupName !== '') {
            Model_createAssetGroup(assetGroupName, eyeCatchUrl)
            props.onClose()
        } else {
            setErrorMessage('名前は必須です')
        }
    }

    return (
        <Box>
            <Dialog open={props.open} onClose={handleOnClose} >
                <DialogTitle>施設の追加</DialogTitle>
                
                <DialogContent sx={{minWidth: 660}}>


                    <Box>
                        <TextField 
                            variant="filled"
                            label='施設の名前'
                            required
                            sx={{m:1, width: '50ch'}}
                            onChange={(e) => {setErrorMessage(''); setAssetGroupName(e.target.value)}}
                        ></TextField>
                    </Box>
                    {
                        errorMessage && 
                        <Box>
                            <Typography sx={{mx: 1, fontSize: 3, color:'red'}}>{errorMessage}</Typography>
                        </Box>
                    }
                    <Box>
                        <TextField
                            variant='filled'
                            label='施設画像URL(任意)'
                            value={eyeCatchUrl}
                            sx={{m: 1, width: '50ch'}}
                            ></TextField>
                            <IconButton onClick={() => setEyeCatchUrl('')}>
                                <HighlightOffIcon />
                            </IconButton>
                    </Box>

                    <Divider></Divider>

                    <Accordion>
                        <AccordionSummary>施設画像サンプル</AccordionSummary>
                        <AccordionDetails>
                            <AssetEyeCatches onClick={setEyeCatchUrl}></AssetEyeCatches>
                       </AccordionDetails>
                    </Accordion>
                    
                
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => handleAddAssetGroup()} >追加</Button>
                    <Button onClick={handleOnClose} >キャンセル</Button>
                </DialogActions>
            </Dialog>

        </Box>
    )
}

export default CreateAssetGroupDialog;
