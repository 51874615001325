
import { Avatar, Tooltip } from '@mui/material'

// created by ChatGPT
/*
function stringToColor2(text: string): string {
    const codePoints: number[] = Array.from(text).map((char) => char.codePointAt(0)!);
    const sum: number = codePoints.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  
    // RGB値を生成
    const red: number = (sum & 0xFF0000) >> 16;
    const green: number = (sum & 0x00FF00) >> 8;
    const blue: number = sum & 0x0000FF;
  
    // 背景色の明度を計算
    const brightness: number = (red * 0.299 + green * 0.587 + blue * 0.114) / 255;
  
    
    // 背景色の明度に基づいて適切な色を生成
    let backgroundColor: string;
    if (brightness > 0.5) {
      // 明るい背景色の場合、RGB値を減少させることで暗めの色にする
      backgroundColor = `#${(((red * 0.8) << 16) | ((green * 0.8) << 8) | (blue * 0.8)).toString(16).padStart(6, '0')}`;
    } else {
      // 暗い背景色の場合、RGB値を増加させることで明るめの色にする
      backgroundColor = `#${(((red * 1.2) << 16) | ((green * 1.2) << 8) | (blue * 1.2)).toString(16).padStart(6, '0')}`;
    }
    
   let backgroundColor = `#${((red << 16) | (green << 8) | blue).toString(16).padStart(6, '0')}`
    
    //console.log("avatar color=" + backgroundColor + 'for ' + text)
    return backgroundColor;
}
*/

function stringToHslColor(str : string, s: number, l: number, a?: number) : string {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    var h = hash % 360;
    if (!a) {
        return 'hsl('+h+', '+s+'%, '+l+'%)';
    } else {
        return 'hsl('+h+', '+s+'%, '+l+'%, '+a+')';
    }
}

export function stringToColor3(text: string, alpha? : number): string {
    const saturation = 80;
    const lightness = 40;
    return stringToHslColor(text, saturation, lightness, alpha)
}


type ColoredAvatarProp = {
    name: string | null,
    email: string | null,
    disableTooltip?: boolean
}

const ColoredAvatar = ({name, email, disableTooltip} : ColoredAvatarProp) => {
    return (
        <Tooltip title={(name && !disableTooltip) ? name: undefined} placement='left'>
            <Avatar alt={name ? name : ""} sx={{backgroundColor: email ? stringToColor3(email) : undefined}} src='/#' >{name ? name.at(0): ''} </Avatar>
        </Tooltip>
    )
}

export default ColoredAvatar;

