import React, { useState } from "react";
import { useNavigate } from "react-router-dom"
import {
  Box,
  Button,
  Container,
  Grid,
  TextField, CssBaseline, Link, Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { Model_createFbUser } from "../db/dbModels";
import UserAuthDialog from "./userAuthDialog";

const CreateUser: React.FC = () => {
  const [displayName, setDisplayName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [agreed, setAgreed] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<{open: boolean, message: string}>({open: false, message: ""});

  const navigate = useNavigate()


  const firebaseAuthErrorMap : Record<string, string> = {
    "auth/invalid-email":         "有効なメールアドレスではありません",
    "auth/invalid-password":      "有効なパスワードではありません。6 文字以上の文字列でパスワードを設定してください",
    "auth/weak-password":      "有効なパスワードではありません。6 文字以上の文字列でパスワードを設定してください",
    "auth/missing-password":      "有効なパスワードではありません。6 文字以上の文字列でパスワードを設定してください",
    "auth/email-already-in-use":  "このメールアドレスは既に登録されています",
    "no-specific-error-message":  "アカウント作成時にエラーが発生しました: ",
  };


  const createFbUser = async () => {
    try {
      if (!agreed) {
        setOpenDialog({open: true, message: "利用規約を読んで「同意」する場合、チェックボックスにチェックを入れてください"});
        return;
      }
      await Model_createFbUser(displayName, email, password);
      navigate("/formPersonInfo");
    } catch (e: any) {
      if (e.message && firebaseAuthErrorMap[e.code]) {
        setOpenDialog({open: true, message: firebaseAuthErrorMap[e.code]});
      } else if (e.message && !firebaseAuthErrorMap[e.code]) {
        setOpenDialog({open: true, message: firebaseAuthErrorMap["no-specific-error-message"] + e.message});
      } else {
        setOpenDialog({open: true, message: firebaseAuthErrorMap["no-specific-error-message"]});
      }
      console.error(e);
    }
  };

  const handleChangeDisplayName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayName(event.currentTarget.value);
  };
  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  };
  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);
  };
  const handleAgreementCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgreed(event.currentTarget.checked);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <Typography component="h4" variant="h4">ユーザ登録</Typography>

        <Box sx={{fontSize: "10px", mt: 5}}>
          <Typography component="body" >必ず、
          <Link href="/termsOfUse" target="_blank" rel="noopener" >
            利用許諾
          </Link>
          をお読みになり、内容をご理解の上、ユーザ登録をお願いいたします。</Typography>
        </Box>

        <Box component="form" sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                style={{ marginTop: "0.5em", marginBottom: "0.5em" }}
                name="displayName"
                label="氏名"
                fullWidth
                variant="outlined"
                value={displayName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangeDisplayName(event);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                style={{ marginTop: "0.5em", marginBottom: "0.5em" }}
                name="email"
                label="E-mail"
                fullWidth
                variant="outlined"
                value={email}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangeEmail(event);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                style={{ marginTop: "0.5em", marginBottom: "0.5em" }}
                name="password"
                label="Password"
                fullWidth
                variant="outlined"
                type="password"
                value={password}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangePassword(event);
                }}
              />
            </Grid>
            <Grid container>
              <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
              <Box sx={{display: "flex", justifyContent: "center"}}>
                <Link href="/termsOfUse" target="_blank" rel="noopener" sx={{pt:3, width:"100%" }}>
                  シェアリング・ヴィレッジ利用許諾
                </Link>
              </Box>
              </Grid>
              <Grid item xs={12}>
                <FormGroup sx={{px: 5, mt: 1}}>
                  <FormControlLabel control={<Checkbox checked={agreed} onChange={handleAgreementCheckBox} />} label="利用許諾を理解して「同意」する"></FormControlLabel>
                </FormGroup>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained"
                fullWidth
                style={{ marginTop: "0.5em", marginBottom: "0.5em" }}
                onClick={createFbUser}
              >
                新規登録
              </Button>
            </Grid>



          </Grid>
        </Box>

        <Grid container justifyContent="flex-end">
          <Grid item>
            <Link href="/login" variant="body2">
              既にアカウントをお持ちの方
            </Link>
          </Grid>
        </Grid>

      </Box>

      {/** Alart dialog box */}
      <UserAuthDialog title="ユーサ登録" 
        message={openDialog.message} open={openDialog.open} 
        onClose={() => {setOpenDialog({open: false, message: ""})}} ></UserAuthDialog>
    </Container>
  );
};

export default CreateUser;
