import { Fragment, useContext } from "react";
import AppMenuBar from "../appMenuBar";
import { Container, Paper } from "@mui/material";
import PersonInfo from "./personInfo";
import { ModePersonalInfo, Model_storePersonalInfo } from "../db/dbModels";
import { AuthContext } from "../authRouting/authProvider";
import { useNavigate } from "react-router-dom";

const FormPersonInfo = () => {
  const { currentUserInfo } = useContext(AuthContext);
  const navigate = useNavigate();

  const onEnter = (data: ModePersonalInfo) => {
    /** TODO: store all data into database */
    console.log(data);
    if (currentUserInfo) {
      Model_storePersonalInfo(currentUserInfo.uid, data);
      navigate("/groupCalendar");
    } else {
      console.error("登録できなかった");
    }
  };

  const onEnterWithError = (errorMessage: string) => {
    /** TODO: show errorMessage on a dialog box */
    console.log(errorMessage);
  }

  return (
    <Fragment>
      <AppMenuBar></AppMenuBar>
      <Container sx={{ mt: 7 }} maxWidth="md">
        <Paper>
          <PersonInfo onEnter={onEnter} onEnterWithError={onEnterWithError}></PersonInfo>
        </Paper>
      </Container>
    </Fragment>
  );
}

export default FormPersonInfo;
