import { Box, Container, Grid, IconButton, Paper, Typography } from "@mui/material";
import AppMenuBar from "../appMenuBar";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ModelUser, Model_getUser } from "../db/dbModels";
import { useEffect, useState } from "react";
import ProfileTable from "../userAuth/profileTable";
import ColoredAvatar from "../userAuth/coloredAvator";

const AdminTheUser = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { userId } = useParams<{ userId?: string }>();
  const [userInfo, setUserInfo] = useState<ModelUser | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (userId) {
        const data = await Model_getUser(userId);
        setUserInfo(data);
      }
    }

    fetchData();

  }, [userId]);

  return (
    <Box>

      <AppMenuBar />

      <Container maxWidth="md" sx={{ position: "relative" }}>
        {/* 戻るボタン*/}
        <Box sx={{ my: 2 }}>
          <IconButton onClick={() => { location.state && location.state.from && navigate(location.state.from) }}>
            <ArrowBackIcon />
          </IconButton>
        </Box>

        <Grid container sx={{display: "flex", justifyContent: "center"}}>
          <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
            <ColoredAvatar
              name={userInfo ? userInfo.name : null}
              email={userInfo ? userInfo.email : null}
              disableTooltip
            />
          </Grid>
          {userInfo?.isAdministrator && (
            <Grid item sx={{display: "flex", justifyContent: "center", background: "pink", mt: 5}} xs={12}>
              <Typography>管理者ユーザー</Typography>
            </Grid>
          )}
        </Grid>
        <Paper>
          <ProfileTable userInfo={userInfo} adminMode={true}></ProfileTable>
        </Paper>

      </Container>

    </Box>
  )
};

export default AdminTheUser;
