import { TableCell, TableBody, TableRow, Tooltip } from '@mui/material';
import { YyMm, calViewResrvData } from './calViewDefs';
import { Holidays } from './holidays';
import dayjs, { Dayjs } from 'dayjs';

type CalViewTableCellProps = {
    disabled?: boolean,
    holiday?: boolean,
    day: Dayjs,
    childKey: number,
    reserve?: calViewResrvData,
    onClickReservation: (resv: calViewResrvData) => void,
    holidayString: string,
}

const CalViewTableCell = ({disabled, holiday, day, childKey, reserve, onClickReservation, holidayString}: CalViewTableCellProps) => {

    const divStyle = {
        cursor: reserve ? 'pointer' : undefined,
        backgroundColor: reserve?.color,
        height: '20px',
        borderTopRightRadius:       reserve && day.isSame(reserve.end, 'day') ? '5px' : undefined,
        borderBottomRightRadius:    reserve && day.isSame(reserve.end, 'day') ? '5px' : undefined,
        borderTopLeftRadius:        reserve && day.isSame(reserve.start, 'day') ? '5px' : undefined,
        borderBottomLeftRadius:     reserve && day.isSame(reserve.start, 'day') ? '5px' : undefined,
    }
    
    const handleOnClick = () => {
        reserve && onClickReservation(reserve)
    }

    let bgColor = undefined;
    if (disabled && !holiday) {
        bgColor = "#f1f1f1" 
    } else if (disabled && holiday) {
        bgColor = '#EDDDDD'
    } else if (holiday) {
        bgColor = '#F8E7E7'
    }

    if (holidayString) {
        return (
            <Tooltip title={holidayString}>
                <TableCell sx={{paddingX:0, backgroundColor: bgColor}} key={childKey}>
                <div>{day.date()}</div>
                    <div style={divStyle} onClick={reserve ? handleOnClick: undefined}></div>
                </TableCell>
            </Tooltip>
        )    
    } else {
        return (
            <TableCell sx={{paddingX:0, backgroundColor: bgColor}} key={childKey}>
            <div>{day.date()}</div>
            <div style={divStyle} onClick={reserve ? handleOnClick: undefined}></div>
            </TableCell>
        )
    
    }
}


type CalendarCellData = {
    resv?: calViewResrvData, day: Dayjs, holiday: boolean, holidayString: string, disabled: boolean,
}

const createCalendarCellData = (yymm: YyMm, reservations : calViewResrvData[], holidays? : Holidays) 
    : CalendarCellData[] => 
{

    const year = yymm.year
    const month = yymm.month
    const week_len = 7

    let count = 0
    let startDayOfWeek = dayjs().year(year).month(month).date(1).day();
    let endDate = dayjs().year(year).month(month).endOf('month').date();
    let rowMax = Math.ceil((startDayOfWeek + endDate)/week_len);

    let d = dayjs().year(year).month(month - 1).endOf('month').subtract(startDayOfWeek-1, 'day')
    let cellDataArray : CalendarCellData[] = new Array<CalendarCellData>(week_len * rowMax);

    [...Array(rowMax)].forEach((e, i) => {
        [...Array(week_len)].forEach((ee, j) => {

            let cellData : CalendarCellData = {resv: undefined, day: dayjs(), holiday: false, holidayString: '', disabled: false};
            reservations.forEach((r) => {
                if (r.start.isSame(d, 'day') || r.end.isSame(d, 'day') || (r.start.isBefore(d, 'day') && r.end.isAfter(d, 'day'))) {
                    cellData.resv = r
                }
            })

            // day (number)
            cellData.day = d;
            
            // holidayString
            if (holidays) {
                cellData.holidayString = holidays.getHolidayString(d.toDate()) 
            }
            // holiday
            cellData.holiday = (d.day() === 0) || (cellData.holidayString !== '')

            // disabled
            if(i === 0 && j < startDayOfWeek) {
                cellData.disabled = true
            } else if(count >= endDate) {
                count++;
                cellData.disabled = true
            } else {
                count++;
            }

            cellDataArray[i * week_len + j] = cellData
            d = d.add(1, 'day')
        })
    })

    return cellDataArray 
}


type CalViewBodyProps = {
    yymm : YyMm,
    reservations: calViewResrvData[],
    holidays?: Holidays,
    onClickReservation: (resv: calViewResrvData) => void,
}

const CalViewBody = ({ yymm, reservations, onClickReservation, holidays}: CalViewBodyProps) => {
    const year = yymm.year
    const month = yymm.month
    const week_len = 7

    let count = 0;
    const startDayOfWeek = new Date(year,month,1).getDay();
    const endDate = new Date(year,month + 1,0).getDate();
    //const lastMonthEndDate = new Date(year,month,0).getDate();
    const rowMax = Math.ceil((startDayOfWeek + endDate)/week_len);
    

    //const rsvMatrix = createResrvMatrix(yymm, reservations)
    const cellDataArray = createCalendarCellData(yymm, reservations, holidays)

    return (
      <TableBody>
        {[...Array(rowMax)].map((_, row) => (
          <TableRow key={row}>
            {[...Array(7)].map((_, wk) => {
                
                const key = row*week_len + wk + 1000;
                //let resv = rsvMatrix[row*week_len + wk]
                let cellData = cellDataArray[count++]
                return (
                        <CalViewTableCell 
                            disabled={cellData.disabled} 
                            holiday={cellData.holiday}
                            reserve={cellData.resv} 
                            day={cellData.day} childKey={row*week_len + wk + 1000} key={key}
                            holidayString={cellData.holidayString}
                            onClickReservation={onClickReservation} />
                    )
            })}
          </TableRow>
        ))}
      </TableBody>
    );
  };

export default CalViewBody