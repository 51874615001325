import { Fragment } from "react"
import AppMenuBar from "../appMenuBar"
import { Container, Paper, } from "@mui/material"

import content_v1 from './content-v1.md';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';             // arrow react-markdown to use html tag which is dangerous

export const TermOfUse_Content_Version = "content-v1";

const TermsOfUse = () => {
  return (
    <Fragment>
      <AppMenuBar></AppMenuBar>
      <Container maxWidth="md" sx={{ mt: 3, mb: 10 }}>
        <Paper sx={{px: "30px", py: "20px"}}>
          {/* TODO: if you do not use HTML tag such as <span style="color: blue">, 
          remove rehype-raw plugin in order to keep security risk away from the markdown*/}
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>{content_v1}</ReactMarkdown> 
        </Paper>
      </Container>
    </Fragment>
  )
}

export default TermsOfUse;