import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import { ModelDataId, } from "../db/dbModels"
import { useReservations } from "./groupCalendarContext"
import { calViewResrvData, formatResvDate } from "./calView/calViewDefs"
import ColoredAvatar from "../userAuth/coloredAvator"

type ReservationListForEachUserProps = {
    uid: ModelDataId,
    username: string,
    email: string,
}


const ReservationListForEachUser = (props: ReservationListForEachUserProps) => {
    const { reservations } = useReservations()

    const resvForUser: calViewResrvData[] =
        reservations.filter((r) => r.uid === props.uid)

    return (
        <>
            <Box sx={{ display: "flex", justifyContent: 'center', mb: 2 }}>
                <Typography variant="h6" alignItems="center">予約一覧</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: 'center' }}>
                <ColoredAvatar name={props.username} email={props.email} disableTooltip />
            </Box>
            <Box sx={{ display: "flex", justifyContent: 'center' }}>
                <Typography>{props.username}</Typography>
            </Box>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>開始日</TableCell>
                        <TableCell>終了日</TableCell>
                    </TableRow>

                </TableHead>

                <TableBody>
                    {resvForUser.map((resvData, i) => (
                        <TableRow key={i}>
                            <TableCell>
                                {formatResvDate(resvData.start)}
                            </TableCell>
                            <TableCell>
                                {formatResvDate(resvData.end)}
                            </TableCell>
                        </TableRow>
                    ))}

                </TableBody>

            </Table>
        </>
    )
}

export default ReservationListForEachUser;
