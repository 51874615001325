import { Fragment, useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ModelAssetGroup } from "../db/dbModels";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import InvalidGroupId from "./invalidGroupId";
import CalendarView from "../groupCalendar/calView/calView";
import { Box, Container, IconButton } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EyeCatchPicture from "./eyeCatchPicture";
import { GroupCalendarContextProvider } from "../groupCalendar/groupCalendarContext";
import AssetGroupInfo from "../groupCalendar/assetGroupInfo";
import ReservationListView from "./reservationListView";

const AdminGroupCalendar = () => {

    const { groupId } = useParams<{ groupId?: string }>();
    const [assetGroup, setAssetGroup] = useState<{valid: boolean, id: string, data?: ModelAssetGroup}>({valid: false, id: ''});
  
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
      if (!groupId) {
        return;
      }
  
      const unsubscribe = onSnapshot(doc(db, 'AssetGroups', groupId), (snapshot) => {
        if (snapshot.exists()) {
          setAssetGroup({ valid: true, id: snapshot.id, data: snapshot.data() as ModelAssetGroup});
        } else {
          setAssetGroup({valid: false, id: ''});
        }
      });
  
      return () => unsubscribe();
    }, [groupId]);

    if (!groupId || !assetGroup.valid) {
        return (<InvalidGroupId />)
    }
 
    return (
        <Fragment>
            <Container maxWidth="md">
            
                {/* 戻るボタン*/ }
                <Box sx={{my: 2}}>
                    <IconButton onClick={() => {location.state && location.state.from && navigate(location.state.from)}}>
                        <ArrowBackIcon />
                    </IconButton>
                </Box>

                {/* アイキャッチ */}
                <EyeCatchPicture
                  url={(assetGroup.data && "eyeCatchUrl" in assetGroup.data) ? assetGroup.data.eyeCatchUrl : undefined}
                  overlappedText={assetGroup.data ? assetGroup.data.name : undefined} />

                <GroupCalendarContextProvider>
                  
                  <CalendarView groupId={groupId} adminMode={true} />
                  <AssetGroupInfo groupId={groupId} adminMode={true}></AssetGroupInfo>
                  <ReservationListView adminMode={true} />

                </GroupCalendarContextProvider>


                <Box sx={{height: "10ch"}}> </Box>
            </Container>
        </Fragment>
    )
}

export default AdminGroupCalendar;