/**********************************************************************************
  アイキャッチ画像
***********************************************************************************/

import { Card, CardMedia, IconButton, Typography } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';

type EyeCatchPictureProp = {
    url?: string,
    overlappedText?: string,
    onEdit?: () => void,
  }
  
  const EyeCatchPicture = (props: EyeCatchPictureProp) => {
    return (
      <Card>
        <CardMedia 
          sx={{position: 'relative', height: 240, 
            display:'flex', justifyContent: 'center', alignItems: 'center', // ←この行は、「NO IMAGE」をセンタリングするためのもの
          }} 
          image={props.url}>

          {props.overlappedText &&
            <Typography 
              alignContent="center" 
              align="center" variant='h4' 
              sx={{p:1, color: 'white', borderRadius: '3px', backgroundColor: "rgba(128, 128, 128, 0.5)"}}> 
                {props.overlappedText}
              </Typography>
          }
  
          {/*  画像変更のボタン */}  
          {props.onEdit &&  
            <IconButton sx={{position: "absolute", bottom: 2, right: 2, backgroundColor: 'white'}} onClick={props.onEdit}>
              <EditIcon fontSize='small'></EditIcon>
            </IconButton>
          }
  
          {/* 画像がない場合のテキスト
          { (!props.url) && 
            (<Typography variant='h6' color='gray' sx={{textAlign: 'center'}}>NO IMAGE</Typography>)
          }
          　*/}
        </CardMedia>
      </Card>
    )
  }

export default EyeCatchPicture;
