
import {Dayjs} from "dayjs";

export class YyMm {
    year: number;
    month: number;

    constructor();
    constructor(arg1: YyMm);
    constructor(arg1: number, arg2: number);
    constructor (arg1?: YyMm | number, arg2?: number) { 
        if ((typeof arg1 === 'number') && (typeof arg2 === 'number')) {
            this.year = arg1
            this.month = arg2    
        } else if (arg1 instanceof YyMm) {
            this.year = arg1.year
            this.month = arg1.month
        } else {
            let d: Date = new Date(Date.now());
            this.year = d.getFullYear();
            this.month = d.getMonth();
        }
    }

    prevMonth(): YyMm {
        let prev = new YyMm(this)
        prev.month--;
        if (prev.month < 0) {
            prev.month = 11;  // 0は一月なので、11は12月を表します
            prev.year--;
        }
        return prev
    }

    nextMonth(): YyMm {
        let next = new YyMm(this)
        next.month++;
        if (next.month > 11) {
            next.month = 0;  // 11は12月なので、0は一月を表します
            next.year++;
        }
        return next
    }
}

export type calViewResrvData = {
    id: string,     // reservation id
    start: Dayjs, 
    end: Dayjs,
    username: string,
    email: string,
    uid: string,    // auth user id 
    color: string, 
    assetGroupId: string,
}

export const calViewDaysStrings: string[] = [ "日", "月", "火", "水", "木", "金", "土"];
 
export const formatResvDate = (d: Dayjs) => {
    return `${d.year()}/${d.month() + 1}/${d.date()} (${calViewDaysStrings[d.day()]})`
}