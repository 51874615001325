import React, { useState, useEffect } from 'react';
import { Container, Box, Typography, Paper, 
  Fab,} from '@mui/material';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import AddIcon from '@mui/icons-material/Add'
import { AssetGroup } from './assetGroupDefs';
import AssetGroupList from './assetGroupList';
import AppMenuBar from '../appMenuBar';
import CreateAssetGroupDialog from './createAssetGroupDialog';

const CreateAssetGroup = () => {
  const [assetGroups, setAssetGroups] = useState<AssetGroup[]>([]);

  useEffect(() => {
    
    const unsubscribe = onSnapshot(collection(db, 'AssetGroups'), (querySnapshot) => {
      const groups: AssetGroup[] = [];
      querySnapshot.forEach((doc) => {
        groups.push({ id: doc.id, name: doc.data().name, url: doc.data().eyeCatchUrl });
      });
      setAssetGroups(groups);
    });

    return () => {
      unsubscribe();
    };

  }, []);

  const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false) 
  const doCreateDialog = () => {
    setOpenCreateDialog(true)
  }
  const onCloseCreateDialog = () => {
    setOpenCreateDialog(false)
  }

  return (
    <Box>

      <AppMenuBar />
      
      <Container maxWidth="md" sx={{position: "relative"}}>

        <Typography variant="h4" gutterBottom sx={{my: '8px'}}>
            施設の管理
        </Typography> 

        {/* 施設追加ボタン */}
        <Fab color='primary' sx={{position: "absolute", top: 24, right: 20}} onClick={() => {doCreateDialog()}}>
          <AddIcon />
        </Fab>

        {/* Dialog */}
        <CreateAssetGroupDialog open={openCreateDialog} onClose={onCloseCreateDialog}></CreateAssetGroupDialog>

        {/* 施設一覧 */}
        <Paper>
          <AssetGroupList assetGroups={assetGroups}></AssetGroupList>
        </Paper>

      </Container>

    </Box>
  );
};

export default CreateAssetGroup;
