
import { TableHead, TableCell, TableRow, tableCellClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { calViewDaysStrings } from './calViewDefs'


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

const CalViewTableHead = () => {
  let days = calViewDaysStrings;
    return (
      <TableHead>
        <TableRow>
            {days.map((d: string) => (
                <StyledTableCell key={d}>{d}</StyledTableCell>
            ))}
        </TableRow>
      </TableHead>
    );
};

export default CalViewTableHead
