import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material"
import AssetEyeCatches from "./assetEyeCatches"
import { useEffect, useState } from "react"
import { ModelDataId, Model_updateEyeCatchUrlOnAssetGroup } from "../db/dbModels"

type UpdateEyeCatchDialogProp = {
    open: boolean,
    onClose: () => void,
    url?: string,
    groupId: ModelDataId,
}

const UpdateEyeCatchDialog = (props: UpdateEyeCatchDialogProp)  => {
    console.log('UpdateEyeCatchDialog: url?=' + props.url)
    const [url, setUrl] = useState<string>(props.url ? props.url : '')

    useEffect(() => {
        if (props.open && props.url) {
            setUrl(props.url)
        }
    }, [props.open, props.url])

    const onClose = () => {
        setUrl('')
        props.onClose()
    }

    const onUpdateUrl = async () => {
        await Model_updateEyeCatchUrlOnAssetGroup(props.groupId, url)
        onClose()
    }

    return (
        <Dialog 
            open={props.open}
            onClose={onClose}
            sx={{minWidth: 660}}
        >   
            <DialogTitle>
                画像の更新
            </DialogTitle>

            <DialogContent>
                <Typography></Typography>
                <TextField 
                    label='画像url'
                    variant="filled"
                    onChange={(e) => {setUrl(e.target.value)}}
                    sx={{width: '60ch'}}
                    value={url}
                    >
                </TextField>

                <AssetEyeCatches onClick={(url) => {setUrl(url)}}></AssetEyeCatches>

            </DialogContent>

            <DialogActions>
                <Button onClick={onUpdateUrl}>決定</Button>
                <Button onClick={onClose}>キャンセル</Button>
            </DialogActions>

        </Dialog>
    )
}

export default UpdateEyeCatchDialog;