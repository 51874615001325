

import { Container, Typography } from "@mui/material"
import { useEffect, useRef, useState, } from "react"
import { Model_createAssetGroup, Model_createFbUser, ModelDataId, Model_assignUserToAssetGroup, Model_createReservation, Model_grantAdmin } from "../db/dbModels"
import dayjs from "dayjs"

const ValidationReseravations = () => {

    const validation_assetGroupId = useRef('')
    const validation_userIds = useRef<ModelDataId[]>([])

    /*
      - テストで確認したいこと。
      
      // 準備
      0-1. 3名のユーザが作る
      0-2. 1個のGroupを作る
      0-3. 1個のグループに0-1で作った３名のユーザを割り当てる
      0-4. テスト予約を作る

      //　確認
      1. Start/Endの順番が正しいこと 
      2. 過去の予約ではないこと
      3. Start/Endの期間にGroupの他のメンバーの予約がないこと
      4. Start/Endの予約期間に自分の予約データがないこと
      5. 予約しようとしているGroupIdが正しいこと
      6. 予約しようとしているUidが正しいこと
    */

    const ValidResv_0_1_User1 = async () : Promise<string> => {
        try {
            const uid = await Model_createFbUser('ゆーざ1', 'user1@gmail.com', 'aabbccdd')
            validation_userIds.current.push(uid)
        } catch (error) {
            if (error instanceof Error) {
                return Promise.resolve('ゆーざ1作成エラー' + error.message)
            } else {
                throw error
            }
        }
        return Promise.resolve('ゆーざ2作成')
    }

    const ValidResv_0_1_User2 = async () : Promise<string> => {
        try {
            const uid = await Model_createFbUser('ゆーざ2', 'user2@gmail.com', 'aabbccdd')
            validation_userIds.current.push(uid)
        } catch (error) {
            if (error instanceof Error) {
                return Promise.resolve('ゆーざ2作成エラー' + error.message)
            } else {
                throw error
            }
        }
        return Promise.resolve('ゆーざ2作成')
    }

    const ValidResv_0_1_User3 = async () : Promise<string> => {
        try {
            const uid = await Model_createFbUser('ゆーざ3', 'user3@gmail.com', 'aabbccdd')
            validation_userIds.current.push(uid)
        } catch (error) {
            if (error instanceof Error) {
                return Promise.resolve('ゆーざ3作成エラー' + error.message)
            } else {
                throw error
            }
        }
        return Promise.resolve('ゆーざ3作成')
    }

    const ValidResv_0_1_User3_admin = async () : Promise<string> => {
        try {
            Model_grantAdmin(validation_userIds.current.slice(-1)[0])
        } catch (error) {
            throw error
        }
        return Promise.resolve('ユーザ3をアドミンにする')
    }

    const ValidResv_0_2_AssetGroup = async () : Promise<string> => {

        try {
            const assetId = await Model_createAssetGroup('テスト・アセットグループ１')
            if (assetId !== '') {
                validation_assetGroupId.current = assetId;
                return Promise.resolve(`AssetGroup (id=${assetId}) を作成`)
            }
        } catch (error) {
            if (error instanceof Error) {
                return Promise.resolve('アセットグループの作成に失敗: ' + error.message)
            }
            return Promise.resolve('アセットグループの作成に失敗: error=' + error)
        }
        return Promise.resolve('Test (2): アセットグループの作成に失敗')
    }

    const ValidResv_0_3_AssetGroupAssignment = async () : Promise<string> => {
        try {
            const arrayOfPromise : Promise<boolean>[] = [];
            validation_userIds.current.forEach((uid) => {
                let p = Model_assignUserToAssetGroup(validation_assetGroupId.current, uid)
                p.catch(e => { console.log(' assign to group error: ' + e.message); throw e;})
                arrayOfPromise.push(p)
            })

            await Promise.all(arrayOfPromise);
            return Promise.resolve(`${arrayOfPromise.length}個のユーザをGroup（${validation_assetGroupId.current}) にアサイン`)

        } catch (error) {
            if (error instanceof Error) {
                return Promise.resolve('アセットグループへのユーザアサイン失敗: ' + error.message)
            }
            return Promise.resolve('アセットグループへのユーザアサイン失敗: error=' + error)
        }
    }

    const ValidResv_0_4 = async () : Promise<string> => {
        const arrayOfTestReservation : {startDateOffset: number, duration: number}[] = [
            {startDateOffset: 7, duration: 3},
            {startDateOffset: 14, duration: 2},
            {startDateOffset: 30, duration: 5},
            {startDateOffset: 45, duration: 10},
        ];

        const arrayOfPromise : Promise<boolean>[] = [];

        const uid = [...validation_userIds.current].pop();
        if (!uid) {
            return Promise.resolve('テスト予約データの取得で、作成する予約に含めるUIDが利用できない')
        }
        
        for (const o of arrayOfTestReservation) {
            const s = dayjs().add(o.startDateOffset, 'day')
            const e = s.add(o.duration, 'day')
            const ret = Model_createReservation(uid, validation_assetGroupId.current, s, e)
            arrayOfPromise.push(ret)
        }

        await Promise.all(arrayOfPromise)
        return Promise.resolve(`${arrayOfTestReservation.length}個のテスト用の予約データを作成しました`)
    }

    const ValidResv_1 = async () : Promise<string> => {

        const uid = [...validation_userIds.current].pop();
        if (!uid) {
            return Promise.resolve('ValidResv_1で、作成する予約に含めるUIDが利用できない')
        }

        const today = dayjs()
        const s = today.add(2, 'day')
        const e = today.add(1, 'day')

        try {
            await Model_createReservation(uid, validation_assetGroupId.current, s, e)
        } catch(e) {
            if (e instanceof Error) {
                return Promise.resolve('OK! 日付順が逆のエラーが正しく返された: メッセージ→ ' + e.message)
            }
        }
        
        return Promise.resolve('NG! 日付順のテスト（ValidResv_1）が期待通りでない')
    }

    // 過去の予約に対して正しくエラーを返すかどうか
    const ValidResv_2 = async () : Promise<string> => {

        const uid = [...validation_userIds.current].pop();
        if (!uid) {
            return Promise.resolve('ValidResv_2で、作成する予約に含めるUIDが利用できない')
        }

        const today = dayjs();
        const s = today.add(-2, 'day');
        const e = today.add(-1, 'day')

        try {
            await Model_createReservation(uid, validation_assetGroupId.current, s, e)
        } catch(e) {
            if (e instanceof Error) {
                return Promise.resolve('OK! 過去の予約に対して正しくエラーが返された: メッセージ→ ' + e.message)
            }
        }
        
        return Promise.resolve('NG! 過去の予約のテスト（ValidResv_2）が期待通りでない')
    }

    // 予約があるところに予約する際にエラーを返すこと
    const ValidResv_3 = async () : Promise<string> => {

        const uid = [...validation_userIds.current].pop();
        if (!uid) {
            return Promise.resolve('ValidResv_3 で、作成する予約に含めるUIDが利用できない')
        }

        const today = dayjs();
        const s = today.add(8, 'day');
        const e = today.add(10, 'day')

        try {
            await Model_createReservation(uid, validation_assetGroupId.current, s, e)
        } catch(e) {
            if (e instanceof Error) {
                return Promise.resolve('OK! 予約がある場合に正しいエラーが返った: メッセージ→ ' + e.message)
            }
        }
        
        return Promise.resolve('NG! 重複予約（ValidResv_3）が期待通りでない')
    }
    

    const ValidResv_DoNotValidate = async () : Promise<string> => {
        return Promise.resolve('localhostのみで実行できます')
    }


    // *** debug output system *** //
    const [debugInfo, setDebugInfo] = useState<{__html: string}>({__html: ''})
    const debugInfoBuffer = useRef('')
    const debugLog = (log: string) => {
        debugInfoBuffer.current = debugInfoBuffer.current + '<br />' + log;
        setDebugInfo({__html: debugInfoBuffer.current})
    }

    // *** useEffect is called twice *** //
    // to prevent calling twice, check if validation loop is started!
    const running = useRef(false)

    // *************************** //
    useEffect(() => {

        const validationFunctions : (() => Promise<string>)[] = 
        (window.location.hostname === 'localhost') ? [
            ValidResv_0_1_User1,
            ValidResv_0_1_User2,
            ValidResv_0_1_User3,
            ValidResv_0_1_User3_admin,
            ValidResv_0_2_AssetGroup,
            ValidResv_0_3_AssetGroupAssignment,
            ValidResv_0_4,
            ValidResv_1,
            ValidResv_2,
            ValidResv_3,

        ] : [
            // localhost ではない場合は、Validationを実行しないようにする
            ValidResv_DoNotValidate,
        ];

        const run = async () => {
            console.log('####### run() on validation ! ')
            for (const func of validationFunctions) {
                const text = await func()
                debugLog(text)
            }
        }

        if (!running.current) {
            run()
            running.current = true
        }
    }, [])

    return (
        <Container sx={{}}>
            <Typography variant="h5"> 予約モデルのテスト </Typography>        
            <Typography id="standard-textarea" dangerouslySetInnerHTML={ debugInfo }></Typography>
        </Container>
   )
}

export default ValidationReseravations