import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../authRouting/authProvider";
import { Box, TableContainer, Table, TableBody, TableRow, TableCell, Container, Paper, Link, Typography } from "@mui/material";
import { Model_getAssetGroup } from "../db/dbModels";
import AppMenuBar from "../appMenuBar";
import ProfileTable from "./profileTable";

const Profile = () => {

    const { currentUserInfo } = useContext(AuthContext)


    return (
        <Box>
            <AppMenuBar></AppMenuBar>


            <Container maxWidth='md'>

                <ProfileTable userInfo={currentUserInfo} adminMode={false}></ProfileTable>
                <Paper sx={{ mt: 2 }}>
                    <Typography sx={{ pt: 5, fontSize: "0.65rem" }}>＊このページの情報は変更できません。変更したい場合は、お手数ですがオーナーまで連絡ください。</Typography>
                </Paper>
            </Container>
        </Box >
    )
}

export default Profile;

