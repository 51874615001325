import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home";
import CreateAssetGroup from './assetGroup/createAssetGroup';
import AssetGroup from './assetGroup/assetGroup';
import CreateUser from './userAuth/createUser';
import Login from './userAuth/login';
import EmailVerified from './userAuth/emailVerified';
import GroupCalendar from './groupCalendar/groupCalendar';
import CreateReservation from './groupCalendar/createReservation';
import PrivateRoute from './authRouting/privateRoute';
import AdminRoute from './authRouting/adminRoute';
import AuthProvider from './authRouting/authProvider';
import Logout from './userAuth/logout';
import Profile from './userAuth/profile';
import ValidationReseravations from './validation/valid_reservations';
import Blog from './blog';
import Page404 from './404';
import AdminGroupCalendar from './assetGroup/adminGroupCalendar';
import FormPersonInfo from './userAuth/FormPersonInfo';
import TermsOfUse from './agreements/TermsOfUse';
import AdminUserList from './admin/adminUserList';
import AdminTheUser from './admin/adminTheUser';

const App = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path={`/`} element={<Home />} />
          <Route path={`/createUser/`} element={<CreateUser />} />
          <Route path={`/createAssetGroup/`} element={<AdminRoute element={<CreateAssetGroup />} />} />
          <Route path={`/assetGroup/:groupId`} element={<AdminRoute element={<AssetGroup />} />} />
          <Route path={`/adminUserList/`} element={<AdminRoute element={<AdminUserList />} />} />
          <Route path={`/adminTheUser/:userId`} element={<AdminRoute element={<AdminTheUser />} />} />
          <Route path={`/adminGroupCalendar/:groupId`} element={<AdminRoute element={<AdminGroupCalendar />} />} />
          <Route path={`/profile`} element={<PrivateRoute element={<Profile />} />} />
          <Route path={`/emailVerified`} element={<EmailVerified />} />
          <Route path={`/login/`} element={<Login />} />
          <Route path={`/logout/`} element={<Logout />} />
          <Route path={`/groupCalendar/`} element={<PrivateRoute element={<GroupCalendar />} />} />
          <Route path={`/createReservation/`} element={<CreateReservation />} />
          <Route path={'/blog'} element={<Blog />} />
          <Route path={'/termsOfUse'} element={<TermsOfUse />} />
          <Route path={`/formPersonInfo/`} element={<PrivateRoute element={<FormPersonInfo />} />} />
          <Route path={'/validation'} element={<ValidationReseravations></ValidationReseravations>} />
          <Route path={'/*'} element={<Page404 />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
