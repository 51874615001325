import React, { useState, useEffect, } from 'react'

import { Box, Button, Table, Typography } from '@mui/material';
import CalViewTableHead from './calViewTableHead'
import CalViewBody from './calViewBody'
import { YyMm, calViewResrvData } from './calViewDefs';
import { useGroupUsers, useReservations, useYymmOnCal } from '../groupCalendarContext';
import { collection, query, where, getDocs, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase'
import { ModelReservation } from '../../db/dbModels';
import CalViewResvDialog from './calViewResvDlg';
import { Holidays } from './holidays';
import dayjs from 'dayjs';


type CalendarViewProps = {
    groupId: string,
    adminMode?: boolean,
}

const CalendarView = ({ groupId, adminMode }: CalendarViewProps) => {
    const {reservations, notifyReservationsUpdated} = useReservations()
    const { groupUsers } = useGroupUsers()
    const { notifyYymmOnCal, yymmOnCal } = useYymmOnCal()
    const [holidays, setHolidays] = useState<Holidays | undefined>(undefined)

    const [calViewResvDialogData, openCalViewResvDialog] = useState<{ open: boolean, resv: calViewResrvData | null }>({ open: false, resv: null })

    const handleClickReservation = (resv: calViewResrvData) => {
        // show dialog!
        openCalViewResvDialog({ open: true, resv: resv })
    }

    const handleResvDialogClose = (selectedString: string) => {
        openCalViewResvDialog({ open: false, resv: null })
    }

    useEffect(() => {
        Holidays.fetch().then((data) => setHolidays(data)).catch((e) => { })
    }, [])

    useEffect(() => {

        const fetch = async () => {
            console.log('CalendarView::userEffect()!')

            const thisYymm = new YyMm()
            const prev = thisYymm.prevMonth()
            //const next = curYyMm.nextMonth()
            // 開始日と終了日の範囲を指定
            const startDate = Timestamp.fromDate(new Date(prev.year, prev.month, 1))
            //const endDate   = Timestamp.fromDate(new Date(next.year, next.month, 8))

            // コレクションの参照を取得
            const reservationsCollectionRef = collection(db, 'Reservations');

            // クエリを構築
            const q = query(
                reservationsCollectionRef,
                where('endDate', '>=', startDate),
                where('assetGroupId', '==', groupId)
            );

            let fetchedData: calViewResrvData[] = []

            // クエリを実行して結果を取得
            const querySnapshot = await getDocs(q);
            console.log('query succeeded data size = ' + querySnapshot.size)
            querySnapshot.forEach((doc) => {
                // ドキュメントのデータを取得
                const rData = doc.data() as ModelReservation
                // 取得したデータを処理するなどの操作を行う
                if (rData.startDate && rData.endDate && rData.uid) {

                    let guser = groupUsers.find((u) => { return u.uid === rData.uid })
                    fetchedData.push({
                        start: dayjs(rData.startDate.toDate()),
                        end: dayjs(rData.endDate.toDate()),
                        color: guser ? guser.bgColor : '#ffffff',
                        username: guser ? guser.name : '',
                        email: guser ? guser.email : '',
                        uid: guser ? guser.uid : '',
                        id: doc.id,
                        assetGroupId: rData.assetGroupId,
                    })
                }
            })

            notifyReservationsUpdated(fetchedData)
        }

        fetch()

    }, [groupUsers, groupId, yymmOnCal, notifyReservationsUpdated])

    const handlePrevMonth = () => { notifyYymmOnCal(yymmOnCal.prevMonth()) }
    const handleNextMonth = () => { notifyYymmOnCal(yymmOnCal.nextMonth()) }

    return (
        <Box sx={{ px: "20px", py: '10px' }}>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>予約カレンダー: {yymmOnCal.year}年{yymmOnCal.month + 1}月</Typography>
            <Typography></Typography>
            <Button onClick={handlePrevMonth}> ＜前の月 </Button>
            <Button onClick={handleNextMonth}> 次の月＞ </Button>
            <Table sx={{ px: '20px' }}>
                <CalViewTableHead />
                <CalViewBody yymm={yymmOnCal} reservations={reservations} holidays={holidays} onClickReservation={handleClickReservation} />
            </Table>

            <CalViewResvDialog adminMode={adminMode ? true: false} selectedValue={''} onClose={handleResvDialogClose} open={calViewResvDialogData.open} resvData={calViewResvDialogData.resv}></CalViewResvDialog>
        </Box>
    )
}

export default CalendarView