

import React, { Fragment } from 'react';
import { Container, Typography } from '@mui/material';
import AppMenuBar from './appMenuBar';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

const Page404 = () => {
  return (
    <Fragment>
      <AppMenuBar />
      <Container maxWidth="md">
        <Typography align='center' sx={{my: 5}}><ReportProblemIcon fontSize='large'/></Typography>
        <Typography variant="h6" align='center' gutterBottom sx={{my: 5}}>
          ページが見つかりません
        </Typography>
      </Container>
   </Fragment>
  )
};

export default Page404;
