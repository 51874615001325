import { ReactElement, createContext, useCallback, useContext, useReducer, } from "react"
import { YyMm, calViewResrvData } from './calView/calViewDefs'

export type UserListData = {
    uid: string,
    name: string,
    email: string,
    color: string,
    bgColor: string,
}

type StateType = {
    groupUsers: UserListData[],
    yymmOnCal: YyMm,
    reservations: calViewResrvData[],
}

const initState: StateType = {
    groupUsers: [],
    yymmOnCal: new YyMm(),
    reservations: [],
}

const enum REDUCER_ACTION_TYPE {
    SET_GROUP_USERS,
    SET_YYMM_ON_CAL,
    SET_RESERVATIONS,
}

type ReducerAction = {
    type: REDUCER_ACTION_TYPE,
    payload?: YyMm | UserListData[] | calViewResrvData[],
}

const reducer = (state: StateType, action: ReducerAction): StateType => {
    switch (action.type) {
        case REDUCER_ACTION_TYPE.SET_GROUP_USERS:
            return { ...state, groupUsers: action.payload as UserListData[] }
        case REDUCER_ACTION_TYPE.SET_YYMM_ON_CAL:
            return { ...state, yymmOnCal: new YyMm(action.payload as YyMm) }
        case REDUCER_ACTION_TYPE.SET_RESERVATIONS:
            return { ...state, reservations: action.payload as calViewResrvData[]}
        default:
            throw new Error('GroupCalenderContext Reducer: invalid action!')
    }
}

export type GroupCalendarContextData = {
    groupUsers: UserListData[],
    yymmOnCal: YyMm,
    notifyGroupUsersUpdated: (users?: UserListData[]) => void,
    notifyYymmOnCal: (yymm?: YyMm) => void,
    reservations: calViewResrvData[],
    notifyReservationsUpdated: (reservations?: calViewResrvData[]) => void,
}

const GroupCalendarContextInitialState: GroupCalendarContextData = {
    groupUsers: [], notifyYymmOnCal: () => { }, 
    notifyGroupUsersUpdated: () => { }, yymmOnCal: new YyMm(),
    reservations: [], notifyReservationsUpdated: () => { },
}

const GroupCalendarContext = createContext<GroupCalendarContextData>(GroupCalendarContextInitialState)

type ChildrenType = {
    children?: ReactElement | ReactElement[] | undefined
}

export const GroupCalendarContextProvider = ({ children }: ChildrenType): ReactElement => {

    console.log('[GroupCalendarContextProvider] is rendered!')
    return (
        <GroupCalendarContext.Provider value={useGroupCalendarContext(initState)}>
            {children}
        </GroupCalendarContext.Provider>
    )
}

const useGroupCalendarContext = (initState: StateType) => {
    const [state, dispatch] = useReducer(reducer, initState)

    const notifyGroupUsersUpdated = useCallback((users?: UserListData[]) => dispatch({ type: REDUCER_ACTION_TYPE.SET_GROUP_USERS, payload: users }), [])

    const notifyYymmOnCal = useCallback((yymm?: YyMm) => {
        console.log(`[notifyYymmOnCal] ${yymm?.year} / ${yymm?.month}`)
        if (yymm) {// 指定された場合は、その月のカレンダーを表示
            dispatch({ type: REDUCER_ACTION_TYPE.SET_YYMM_ON_CAL, payload: new YyMm(yymm) })
        } else {
            dispatch({ type: REDUCER_ACTION_TYPE.SET_YYMM_ON_CAL, payload: new YyMm(state.yymmOnCal) })
        }
    }, [state.yymmOnCal])

    const notifyReservationsUpdated = useCallback((reservations?: calViewResrvData[]) => {
        dispatch({ type: REDUCER_ACTION_TYPE.SET_RESERVATIONS, payload: reservations})
    }, [])

    return { 
        groupUsers: state.groupUsers, notifyGroupUsersUpdated, 
        yymmOnCal: state.yymmOnCal, notifyYymmOnCal,
        reservations: state.reservations, notifyReservationsUpdated, }
}

export const useGroupUsers = () => {
    const { groupUsers, notifyGroupUsersUpdated } = useContext(GroupCalendarContext)
    return { groupUsers, notifyGroupUsersUpdated }
}

export const useYymmOnCal = () => {
    const { yymmOnCal, notifyYymmOnCal } = useContext(GroupCalendarContext)
    return { yymmOnCal, notifyYymmOnCal }
}

export const useReservations = () => {
    const {reservations, notifyReservationsUpdated} = useContext(GroupCalendarContext)
    return {reservations, notifyReservationsUpdated}
}