
import { Fragment, useEffect, useState } from "react"
import AppMenuBar from "../appMenuBar"
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material"
import { ModelDataId, ModelUser, Model_getAdminUserList } from "../db/dbModels";
import { AssetGroup } from "../assetGroup/assetGroupDefs";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Link as MuiLink } from '@mui/material';
import ColoredAvatar from "../userAuth/coloredAvator";


type AssetGroupLinkProps = {
  assetGroups: AssetGroup[],
  groupId: ModelDataId,
}

const AssetGroupLink = ({ assetGroups, groupId }: AssetGroupLinkProps) => {
  const location = useLocation();

  const findAssetGroup = (assetGroups: AssetGroup[], groupId: ModelDataId): AssetGroup | undefined => {
    return assetGroups.find((value) => value.id === groupId);
  }

  const groupAsset = findAssetGroup(assetGroups, groupId);
  if (groupAsset) {
    return (
      <MuiLink component={Link} to={`/adminGroupCalendar/${groupId}`} state={{ from: location.pathname }}>
        {groupAsset.name}
      </MuiLink>
    );
  } else {
    return (<>未登録</>);
  }
}

const AdminUserList = () => {
  const location = useLocation();
  const [users, setUsers] = useState<ModelUser[]>([]);
  const [assetGroups, setAssetGroups] = useState<AssetGroup[]>([]);

  useEffect(() => {

    const fetchUsers = async () => {
      const list = await Model_getAdminUserList();
      setUsers(list);
    }

    fetchUsers();
  }, []);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'AssetGroups'), (querySnapshot) => {
      const groups: AssetGroup[] = [];
      querySnapshot.forEach((doc) => {
        groups.push({ id: doc.id, name: doc.data().name, url: doc.data().eyeCatchUrl });
      });
      setAssetGroups(groups);
    });

    return () => {
      unsubscribe();
    };
  }, [users]);

  return (
    <Fragment>
      <AppMenuBar></AppMenuBar>
      <Container sx={{ mt: 7 }}>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>avatar</TableCell>
              <TableCell>ニックネーム</TableCell>
              <TableCell>契約者名</TableCell>
              <TableCell>メールアドレス</TableCell>
              <TableCell>登録施設</TableCell>
            </TableRow>

          </TableHead>

          <TableBody>
            {users.map((user, i) => (
              <TableRow key={i}>
                <TableCell>
                  <ColoredAvatar
                    name={user.name}
                    email={user.email}
                    disableTooltip
                  />
                </TableCell>
                <TableCell>
                  <MuiLink component={Link} to={`/adminTheUser/${user.uid}`} state={{ from: location.pathname }}>
                    {user.name}
                  </MuiLink>
                </TableCell>
                <TableCell>
                  {user.personalInfo ? user.personalInfo.fullName : "（未登録）"}
                </TableCell>
                <TableCell>
                  {user.email}
                </TableCell>
                <TableCell>
                  <AssetGroupLink assetGroups={assetGroups} groupId={user.assetGroupId} />
                </TableCell>
              </TableRow>
            ))}

          </TableBody>

        </Table>
      </Container>
    </Fragment>
  );
}

export default AdminUserList;

