import {
    FieldValue, Timestamp,
    setDoc, addDoc, getDoc, getDocs, updateDoc,
    doc, collection,
    runTransaction, query, where,
    serverTimestamp,
    DocumentReference,
    DocumentData,
    deleteField,
    Unsubscribe,
    onSnapshot,
    deleteDoc,
    QueryConstraint
} from "firebase/firestore"

import { createUserWithEmailAndPassword, sendEmailVerification, updateProfile, User } from "firebase/auth"
import { db, auth } from '../firebase'
import dayjs, { Dayjs } from "dayjs"
import { Mail_cancelReservation, Mail_createReservation, Mail_createUser } from "../mail/mail"
import { TermOfUse_Content_Version } from "../agreements/TermsOfUse"

const MaxLimitReservationDaysInMonth = 6;

export type ModelDataId = string

/************************************************************************** */
/* Model User
/************************************************************************** */

export type ModePersonalInfo = {
    postalCode: string,
    address: string,
    fullName: string,
    phoneNumber: string,
    carInfo: string,
}

export type ModelUser = {
    name: string | null,
    email: string | null,
    uid: ModelDataId,
    assetGroupId: ModelDataId,
    isAdministrator: boolean,

    /** contract agreement */
    agreementVersion?: string,
    agreementDate?: Timestamp,

    /** personal information */
    personalInfo?: ModePersonalInfo,
}

export const Model_createFbUser = async (displayName: string, email: string, password: string): Promise<ModelDataId> => {

    const storeInitialUserEntry = async (user: User): Promise<ModelDataId> => {
        if (user) {
            const userData: ModelUser = {
                name: user.displayName,
                email: user.email,
                uid: user.uid,
                assetGroupId: "",
                isAdministrator: false, // 初期値としてfalseを設定,
            };
            await setDoc(doc(db, 'Users', user.uid), userData);
            await Mail_createUser(user.uid, userData);
            return Promise.resolve(user.uid)
        }
        return Promise.resolve('')
    }

    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password)
        await updateProfile(userCredential.user, { displayName: displayName })
        await sendEmailVerification(userCredential.user)
        return storeInitialUserEntry(userCredential.user)
    }
    catch (error) {
        return Promise.reject(error);
    }
}

export const Model_storePersonalInfo = async (uid: ModelDataId, personalInfo: ModePersonalInfo): Promise<ModelDataId> => {

    try {
        await runTransaction(db, async (transaction) => {
            const ref = doc(db, 'Users', uid);
            const snapshot = await transaction.get(ref);
    
            if (!snapshot.exists()) {
                throw new Error('Data associated with Uid does not exist.');
            }
    
            transaction.update(ref, {
                personalInfo: personalInfo,
                agreementVersion: TermOfUse_Content_Version,
                agreementDate: Timestamp.fromDate(new Date()),
            });
        })
    } catch (error) {
        return Promise.reject(error)
    }

    return Promise.resolve(uid);
}

export const Model_getUser = async (uid: ModelDataId): Promise<ModelUser | null> => {

    const snapshot = await getDoc(doc(db, 'Users', uid))
    if (snapshot.exists()) {
        return Promise.resolve(snapshot.data() as ModelUser)
    }

    return Promise.resolve(null)
}

export const Model_getUnassignedUserList = async (): Promise<ModelUser[]> => {

    // コレクションの参照を取得
    const usersCollectionRef = collection(db, 'Users');

    // クエリを構築
    const q = query(
        usersCollectionRef,
        where('assetGroupId', '==', "")
    );

    const users: ModelUser[] = [];

    // クエリを実行して結果を取得
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        // ドキュメントのデー    
        users.push(doc.data() as ModelUser)
    })

    return Promise.resolve(users)
}

export const Model_subscribeUnassignedUserList = (callback: (users: ModelUser[]) => void): Unsubscribe => {
    // コレクションの参照を取得
    const usersCollectionRef = collection(db, 'Users');

    // クエリを構築
    const q = query(
        usersCollectionRef,
        where('assetGroupId', '==', "")
    );

    // クエリを実行して結果を取得
    const querySnapshot = onSnapshot(q, (snapshot) => {
        const users: ModelUser[] = [];
        snapshot.forEach((doc) => {
            users.push(doc.data() as ModelUser)
        })

        callback(users)
    });

    return querySnapshot
}

export const Model_subscribeUser = (uid: ModelDataId, callback: (user: ModelUser) => void): Unsubscribe | null=> {

    if (!uid) {
        return null;
    }

    const unsubscribe = onSnapshot(doc(db, "Users", uid), (doc) => {
        console.log("Current data: ", doc.data());
        callback(doc.data() as ModelUser);
    });

    return unsubscribe;
}



export const Model_grantAdmin = async (uid: ModelDataId): Promise<boolean> => {
    const docRef = await doc(db, 'Users', uid)
    await updateDoc(docRef, {
        isAdministrator: true,
    })
    return Promise.resolve(true)
}

export const Model_getAdminUserList = async (): Promise<ModelUser[]> => {

    // コレクションの参照を取得
    const usersCollectionRef = collection(db, 'Users');

    // クエリを構築
    const q = query(
        usersCollectionRef
    );

    const users: ModelUser[] = [];

    // クエリを実行して結果を取得
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        // ドキュメントのデー    
        users.push(doc.data() as ModelUser)
    })

    return Promise.resolve(users);
}
/************************************************************************** */
/* Model Reservation
/************************************************************************** */

export type ModelReservation = {
    startDate: Timestamp | null,
    endDate: Timestamp | null,
    timestamp: FieldValue,
    assetGroupId: ModelDataId,
    uid: ModelDataId,
}

/* startDate, endDateの期間に他の予約がないことの確認。あったらTrueが返る */
const checkIfOverlapReservationExists = async (groupId: ModelDataId, startDate: Dayjs, endDate: Dayjs): Promise<boolean> => {

    // 開始日と終了日の範囲を指定
    const tsStartDate = Timestamp.fromDate(startDate.toDate())
    //const endDate   = Timestamp.fromDate(new Date(next.year, next.month, 8))

    // コレクションの参照を取得
    const reservationsCollectionRef = collection(db, 'Reservations');

    // クエリを構築
    const q = query(
        reservationsCollectionRef,
        where('endDate', '>=', tsStartDate),
        where('assetGroupId', '==', groupId)
    );

    // クエリを実行して結果を取得
    const querySnapshot = await getDocs(q);
    console.log('query succeeded data size = ' + querySnapshot.size)
    let isOverlap = false
    querySnapshot.forEach((doc) => {
        // ドキュメントのデータを取得
        const rData = doc.data() as ModelReservation
        // 取得したデータを処理するなどの操作を行う
        if (rData.startDate && rData.endDate && rData.uid) {

            const aStart = dayjs(rData.startDate.toDate())
            const aEnd = dayjs(rData.endDate.toDate())

            const checkOverlap = aStart.isSame(startDate) ||
                aEnd.isSame(endDate) ||
                (aStart.isBefore(endDate) && aEnd.isAfter(startDate));

            if (checkOverlap) {
                isOverlap = true
                console.log(`overlap found: ${aStart.format('MM/DD')} - ${aEnd.format('MM/DD')}`)
                return
            }
        }
    })

    return Promise.resolve(isOverlap)
}

const checkIfGroupHasUser = async (uid: ModelDataId, groupId: ModelDataId): Promise<boolean> => {

    const snapshot = await getDoc(doc(db, 'Users', uid))
    if (!snapshot.exists()) {
        return Promise.reject(new Error(`User data for uid=${uid} doesn't exist`))
    }

    const userData: ModelUser = snapshot.data() as ModelUser
    if (userData.assetGroupId !== groupId) {
        return Promise.resolve(false)
    }

    return Promise.resolve(true)
}


/**
 * The function below is mainly written by Claude 3
 * 
 * 指定された期間内に、ユーザーの予約日数が制限日数を超えていないかをチェックする
 *
 * @param uid ユーザーID
 * @param startDate 予約開始日
 * @param endDate 予約終了日
 * @param limitDays 1ヶ月の予約可能日数の制限
 * @returns 制限日数以内の場合は true、超える場合は false
 */
const checkIfUnderLimitDaysReservationInMonth = async (
    uid: ModelDataId,
    groupId: ModelDataId,
    startDate: Dayjs,
    endDate: Dayjs,
    limitDays: number
): Promise<boolean> => {
    const reservationsRef = collection(db, 'Reservations');

    // 予約が跨る全ての月を取得
    const monthsInvolved: number[] = [];
    let currentMonth = startDate.month();
    let endMonth = endDate.month();
    while (currentMonth !== endMonth) {
        monthsInvolved.push(currentMonth);
        currentMonth = (currentMonth + 1) % 12;
    }
    monthsInvolved.push(endMonth);

    // 各月について、制限日数以内かどうかをチェック
    for (const month of monthsInvolved) {
        const startOfMonth = dayjs().year(startDate.year()).month(month).startOf('month');
        const endOfMonth = dayjs().year(startDate.year()).month(month).endOf('month');

        const queryConstraints: QueryConstraint[] = [
            where('uid', '==', uid),
            where('assetGroupId', '==', groupId),
            where('startDate', '>=', Timestamp.fromDate(startOfMonth.toDate())),
            where('startDate', '<=', Timestamp.fromDate(endOfMonth.toDate())),
        ];
        const userReservationsInMonthStarting = await getDocs(query(reservationsRef, ...queryConstraints));

        queryConstraints[2] = where('endDate', '>=', Timestamp.fromDate(startOfMonth.toDate()));
        queryConstraints[3] = where('endDate', '<=', Timestamp.fromDate(endOfMonth.toDate()));
        const userReservationsInMonthEnding = await getDocs(query(reservationsRef, ...queryConstraints));

        const userReservationsInMonth = [...userReservationsInMonthStarting.docs, ...userReservationsInMonthEnding.docs];
        const uniqueReservations = userReservationsInMonth.filter((value, index, self) =>
            self.findIndex((doc) => doc.id === value.id) === index
        );

        let totalDays = 0;
        uniqueReservations.forEach((doc) => {
            const reservation = doc.data() as ModelReservation;
            const reservationStartDate = dayjs(reservation.startDate?.toDate());
            const reservationEndDate = dayjs(reservation.endDate?.toDate());
            totalDays += reservationEndDate.diff(reservationStartDate, 'days') + 1;
        });

        // 新しい予約を加えた場合に制限日数を超えるかどうかをチェック
        const newReservationDays = endDate.diff(startDate, 'days') + 1;
        if (totalDays + newReservationDays > limitDays) {
            return false;
        }
    }

    return true;
};


export const Model_createReservation = async (
    uid: ModelDataId, groupId: ModelDataId,
    startDate: Dayjs, endDate: Dayjs): Promise<boolean> => {

    console.log("開始日と終了日の確認...");
    // 開始日と終了日の確認
    if (startDate.isAfter(endDate)) {
        return Promise.reject(new Error('予約の開始日が終了日の後になっています'))
    }
    console.log("開始日と終了日の確認...ok");

    console.log("過去の日付だ...");
    // 過去の日付だ
    const today = dayjs()
    if (startDate.isBefore(today) || endDate.isBefore(today)) {
        return Promise.reject(new Error('過去の日付では予約できません'))
    }
    console.log("過去の日付だ...ok");

    console.log("過指定レンジに予約があるかどうかの確認...");
    // 指定レンジに予約があるかどうかの確認
    if (await checkIfOverlapReservationExists(groupId, startDate, endDate)) {
        return Promise.reject(new Error('すでに予約されている期間です'))
    }
    console.log("過指定レンジに予約があるかどうかの確認...ok");

    console.log("予約しようとしているUID,GroupIDが正しいか確認...");
    // 予約しようとしているUID,GroupIDが正しいか確認
    if (! await checkIfGroupHasUser(uid, groupId)) {
        return Promise.reject(new Error('Internal Error: UIDがAssetGroupに属さない'))
    }

    console.log("予約しようとしているUID,GroupIDが正しいか確認...ok");

    console.log("月の予約日数が、MaxLimitReservationDaysInMonth以内かどうか確認...");
    // 月の予約日数が、MaxLimitReservationDaysInMonth以内かどうか確認
    if (! await checkIfUnderLimitDaysReservationInMonth(uid, groupId, startDate, endDate, MaxLimitReservationDaysInMonth)) {
        return Promise.reject(new Error(`ひと月に予約できる日数は最大 ${MaxLimitReservationDaysInMonth}日です`));
    }
    console.log("月の予約日数が、MaxLimitReservationDaysInMonth以内かどうか確認...ok");

    // 予約を作成してFirebaseのDBに保存する処理
    const reservationData: ModelReservation = {
        startDate: startDate ? Timestamp.fromDate(startDate.toDate()) : null,
        endDate: endDate ? Timestamp.fromDate(endDate.toDate()) : null,
        timestamp: serverTimestamp(),
        assetGroupId: groupId,
        uid: uid,
    }

    console.log(`書き込み(${JSON.stringify(reservationData)})...`);
    // 書き込み
    await addDoc(collection(db, 'Reservations'), reservationData);
    console.log(`書き込みok`);

    // メール
    await Mail_createReservation(uid, groupId, startDate, endDate);

    return Promise.resolve(true)
}

export const Model_cancelReservation = async (
    uid: ModelDataId, groupId: ModelDataId,
    reservationId: ModelDataId, startDate: Dayjs, endDate: Dayjs): Promise<void> => {

    const reservationDocRef = doc(collection(db, 'Reservations'), reservationId);

    try {
        // DBから削除
        await deleteDoc(reservationDocRef);
        console.log('予約が削除されました。ID=' + reservationId);

        // メール送信
        Mail_cancelReservation(uid, groupId, startDate, endDate);

    } catch (e) {
        console.error("予約がキャンセルできませんでした。ID=" + reservationId);
        return Promise.reject();
    }
    return Promise.resolve();

}

export const Model_getReservationsAssosicatedWith = async (uid: ModelDataId): Promise<ModelReservation[]> => {

    const startDate = Timestamp.fromDate(new Date(Date.now()))
    //const endDate   = Timestamp.fromDate(new Date(next.year, next.month, 8))

    // コレクションの参照を取得
    const reservationsCollectionRef = collection(db, 'Reservations');

    // クエリを構築
    const q = query(
        reservationsCollectionRef,
        where('endDate', '>=', startDate),
        where('uid', '==', uid)
    );

    let fetchedData: ModelReservation[] = []

    // クエリを実行して結果を取得
    const querySnapshot = await getDocs(q);
    console.log('query succeeded data size = ' + querySnapshot.size)
    querySnapshot.forEach((doc) => {
        // ドキュメントのデータを取得
        const rData = doc.data() as ModelReservation
        fetchedData.push(rData)
    })

    return Promise.resolve(fetchedData)
}

/************************************************************************** */
/* Model Asset Group
/************************************************************************** */

export type ModelAssetGroup = {
    name: string,
    users: ModelDataId[],
    eyeCatchUrl?: string,
}

export const Model_createAssetGroup = async (groupName: string, eyeCatchUrl?: string): Promise<string> => {

    let data: any = eyeCatchUrl && (eyeCatchUrl !== '') ? {
        name: groupName,
        users: [],
        eyeCatchUrl: eyeCatchUrl,
    } : {
        name: groupName,
        users: [],
    }

    const docRef = await addDoc(collection(db, 'AssetGroups'), data);
    console.log('アセットグループが作成されました:', docRef.id);
    return Promise.resolve(docRef.id)
}

export const Model_assignUserToAssetGroup = async (groupId: ModelDataId, uid: ModelDataId): Promise<boolean> => {

    try {

        await runTransaction(db, async (transaction) => {
            const assetGroupRef = doc(db, 'AssetGroups', groupId);
            const assetGroupSnapshot = await transaction.get(assetGroupRef);

            if (!assetGroupSnapshot.exists()) {
                throw new Error('Asset Group does not exist.');
            }

            const users = assetGroupSnapshot.data()?.users || [];
            const updatedUsers = [...users, uid];

            const userRef = doc(db, 'Users', uid);
            const userSnapshot = await transaction.get(userRef);

            if (!userSnapshot.exists()) {
                throw new Error('User does not exist.');
            }

            transaction.update(assetGroupRef, {
                users: updatedUsers,
            });

            transaction.update(userRef, {
                assetGroupId: groupId,
            });
        });

    } catch (error) {
        return Promise.reject(error)
    }

    return Promise.resolve(true)
}

export const Model_removeUserFromAssetGroup = async (groupId: ModelDataId, uid: ModelDataId): Promise<boolean> => {

    try {

        await runTransaction(db, async (transaction) => {
            const assetGroupRef = doc(db, 'AssetGroups', groupId);
            const assetGroupSnapshot = await transaction.get(assetGroupRef);

            if (!assetGroupSnapshot.exists()) {
                throw new Error('Asset Group does not exist.');
            }

            const users = assetGroupSnapshot.data()?.users || [];
            const updatedUsers: ModelDataId[] = [] // [...users, uid];
            users.forEach((e: ModelDataId) => {
                if (e !== uid) {
                    updatedUsers.push(e)
                }
            })

            const userRef = doc(db, 'Users', uid);
            const userSnapshot = await transaction.get(userRef);

            if (!userSnapshot.exists()) {
                throw new Error('User does not exist.');
            }

            transaction.update(assetGroupRef, {
                users: updatedUsers,
            });

            transaction.update(userRef, {
                assetGroupId: '',
            });
        });

    } catch (error) {
        return Promise.reject(error)
    }

    return Promise.resolve(true)
}

export const Model_getAssetGroup = async (groupId: ModelDataId): Promise<ModelAssetGroup | null> => {

    const snapshot = await getDoc(doc(db, 'AssetGroups', groupId))
    if (snapshot.exists()) {
        return Promise.resolve(snapshot.data() as ModelAssetGroup)
    }

    return Promise.resolve(null)
}

export const Model_modifyAssetGroupName = async (groupId: ModelDataId, name: string): Promise<boolean> => {
    const assetGroupRef = doc(db, 'AssetGroups', groupId);
    await updateDoc(assetGroupRef, {
        name: name,
    })

    return Promise.resolve(true)
}

export const Model_updateEyeCatchUrlOnAssetGroup = async (groupId: ModelDataId, url: string): Promise<boolean> => {
    const assetGroupRef = doc(db, 'AssetGroups', groupId);

    if (!url || url === '') {
        await updateDoc(assetGroupRef, {
            eyeCatchUrl: deleteField(),
        })
    } else {
        await updateDoc(assetGroupRef, {
            eyeCatchUrl: url,
        })
    }
    return Promise.resolve(true)
}

export const Model_deleteAssetGroup = async (groupId: ModelDataId): Promise<boolean> => {

    try {

        await runTransaction(db, async (transaction) => {
            const assetGroupRef = doc(db, 'AssetGroups', groupId);
            const assetGroupSnapshot = await transaction.get(assetGroupRef);

            if (!assetGroupSnapshot.exists()) {
                throw new Error('Asset Group does not exist.');
            }

            const users = assetGroupSnapshot.data()?.users || [];
            const userRefs: DocumentReference<DocumentData>[] = []
            for (const u of users) {
                const userRef = doc(db, 'Users', u)
                const userSnapshot = await transaction.get(userRef);
                if (userSnapshot.exists()) {
                    userRefs.push(userRef)
                }
            }

            transaction.delete(assetGroupRef);

            for (const userRef of userRefs) {
                transaction.update(userRef, {
                    assetGroupId: '',
                });
            }
        });

    } catch (error) {
        return Promise.reject(error)
    }

    return Promise.resolve(false)
}
