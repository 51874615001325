
import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridComparatorFn, GridRenderCellParams, gridStringOrNumberComparator, } from '@mui/x-data-grid';
import { useReservations } from '../groupCalendar/groupCalendarContext';
import { formatResvDate } from '../groupCalendar/calView/calViewDefs';
import { Paper, Typography } from '@mui/material';
import ColoredAvatar from '../userAuth/coloredAvator';
import { ModelDataId } from '../db/dbModels';

const avatarComparator: GridComparatorFn<{username: string, email: string}> = (v1, v2, cellParam1, cellParam2) => 
    gridStringOrNumberComparator(v1.username, v2.username, cellParam1, cellParam2)

const columnsAdmin: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 45 },
    { 
        field: 'avatar', 
        headerName: 'Avatar', 
        width: 100,
        renderCell: (params: GridRenderCellParams<{username: string, email: string}>) => (
              <ColoredAvatar
                name={params.value.username}
                email={params.value.email}
                disableTooltip
              />
          ),
        sortComparator: avatarComparator
    },
    {
        field: 'user',
        headerName: '名前',
        width: 150,
    },
    {
        field: 'startDate',
        headerName: '開始日',
        width: 150,
    },
    {
        field: 'endDate',
        headerName: '終了日',
        type: 'number',
        width: 150,
    },
];

const columnsUser: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 45 },
    {
        field: 'startDate',
        headerName: '開始日',
        width: 150,
    },
    {
        field: 'endDate',
        headerName: '終了日',
        type: 'number',
        width: 150,
    },
];


type RowDataType = {
    id: number, user: string, startDate: string, endDate: string, avatar: {username: string, email: string},
}

export type ReservationListViewProps = {
    adminMode?: boolean,
    currentUserId?: ModelDataId,
};

const ReservationListView = ({adminMode, currentUserId} : ReservationListViewProps) => {

    const { reservations } = useReservations();

    const rows: RowDataType[] = [];

    // admin modeの場合は、すべての予約を表示する
    // admin modeでない場合は、現在ユーザの予約だけを表示する
    if (adminMode) {
        reservations.forEach((r, i) => {
            rows.push({
                id: i + 1,
                user: r.username,
                startDate: formatResvDate(r.start),
                endDate: formatResvDate(r.end),
                avatar: {username: r.username, email: r.email}
            })
        })
    } else {
        let index = 1;
        reservations.forEach((r, i) => {
            if (currentUserId && currentUserId == r.uid) {
                rows.push({
                    id: index++,
                    user: r.username,
                    startDate: formatResvDate(r.start),
                    endDate: formatResvDate(r.end),
                    avatar: {username: r.username, email: r.email}
                });
            }
        })
    }

    return (
        <>
            <Box sx={{ height: 2}}></Box>
            <Paper>
                <Typography sx={{ paddingX: "20px", py: "10px" }} component="h2" variant="h6" color="primary" gutterBottom>予約一覧</Typography>
                <Typography sx={{ paddingX: "20px", py: "5px"}} fontSize="small">＊古い予約は表示されません</Typography>
                <Box sx={{ height: 520, width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={adminMode ? columnsAdmin: columnsUser}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 7,
                                },
                            },
                        }}
                        pageSizeOptions={[7]}
                        checkboxSelection
                        disableRowSelectionOnClick
                    />
                </Box>
            </Paper>
        </>
    );
}

export default ReservationListView;