import { Fragment } from "react";
import AppMenuBar from "../appMenuBar";
import { Container, Typography } from "@mui/material";

import ReportIcon from '@mui/icons-material/Report';
import { Link, useLocation } from "react-router-dom";
import { Link as MuiLink } from '@mui/material';

const PersonInfoNotStored = () => {

  const location = useLocation();

  return (
    <Fragment>
      <AppMenuBar></AppMenuBar>
      <Container maxWidth="md">
        <Typography align='center' sx={{ m: 8 }}>
          <ReportIcon fontSize='large' />
        </Typography>
        <Typography align='center' variant='h5' gutterBottom>
          ユーザ情報がすべて登録されていません
        </Typography>
        <Typography align='center' gutterBottom sx={{ m: 2 }}>
          <MuiLink component={Link} to={`/formPersonInfo`} state={{ from: location.pathname }}>
            「ユーザー情報の登録」
          </MuiLink>
          画面から登録してください。
        </Typography>
      </Container>
    </Fragment>
  )
}

export default PersonInfoNotStored;
