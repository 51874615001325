

export const eyeCatchPics : {url: string, roomNum: number} [] = [
    {roomNum: 309, url: 'https://hydrangea-hakone.com/wp-content/uploads/2023/06/309-room-color.jpg'}, 
    {roomNum: 308, url: 'https://hydrangea-hakone.com/wp-content/uploads/2023/06/308-room-color.jpg'}, 
    {roomNum: 306, url: 'https://hydrangea-hakone.com/wp-content/uploads/2023/06/306-room-color.jpg'}, 
    {roomNum: 305, url: 'https://hydrangea-hakone.com/wp-content/uploads/2023/06/305-loft-color.jpg'}, 
    {roomNum: 301, url: 'https://hydrangea-hakone.com/wp-content/uploads/2023/06/301-room-color.jpg'}, 
    {roomNum: 307, url: 'https://hydrangea-hakone.com/wp-content/uploads/2023/06/207-wall-color.jpg'},
    {roomNum: 20306, url: 'https://hydrangea-hakone.com/wp-content/uploads/2023/06/306-kitchen-mono.jpg'}, 
    {roomNum: 20207, url: 'https://hydrangea-hakone.com/wp-content/uploads/2023/06/207-window-mono.jpg'},    
]

