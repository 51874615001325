import { useState, useEffect, Fragment } from "react"
import { Box, List, ListItem, ListItemText, Button, IconButton, 
    Dialog, DialogTitle, DialogContent, DialogActions,
    Typography,
    Tooltip, 
    Paper} from "@mui/material"
import { ModelDataId, ModelUser, Model_getUser } from "../db/dbModels"
import ColoredAvatar from "../userAuth/coloredAvator"
import DeleteIcon from '@mui/icons-material/Delete';


type DeleteComfirmDialogProp = {
    onClose: () => void,
    onDeleteUser: (uid: ModelDataId) => void,
    open: boolean,
    user: ModelUser | null,
}

const DeleteComfirmDialog = (props : DeleteComfirmDialogProp) => {
    return (
        <Dialog open={props.open} onClose={props.onClose}>

            <DialogTitle>ユーザの削除</DialogTitle>
            <DialogContent sx={{minWidth: 360}}>
                <Typography>「{props.user ? props.user.name : ''}」を削除しますか？</Typography>
            </DialogContent>
            
            <DialogActions>
                <Button onClick={props.onClose} autoFocus> いいえ </Button>
                <Button onClick={() => {props.user && props.onDeleteUser(props.user.uid); props.onClose()}}> はい </Button>
            </DialogActions>
        </Dialog>
    )
}

type AssetGroupUsersProp = {
    userids: string[],
    onDeleteUser: (uid: ModelDataId) => void,
}

const AssetGroupUsers = ({userids, onDeleteUser} : AssetGroupUsersProp) => {

    const [users, setUsers] = useState<ModelUser[]>([]);
    const [openDeleteConfirmDialog, setOpenDeleteConfrimDialog] = useState<boolean>(false);
    const [deletingUser, setDeletingUser] = useState<ModelUser | null>(null);

    const handleDeleteUser = (user: ModelUser) => {
        setDeletingUser(user)
        setOpenDeleteConfrimDialog(true)
    }

    const handleDeleteComfirmDialogClose = () => {
        setOpenDeleteConfrimDialog(false)
    }

    useEffect(() => {

        const fetch = async () => {
            let fetchedData : ModelUser[] = [];
            for (const uid of userids) {
                const data = await Model_getUser(uid)
                if (data) {
                    fetchedData.push(data)
                }
            }
            setUsers(fetchedData)    
        }

        fetch()
        
    }, [userids])

    return (
        <Fragment>
            <Paper>
                <Typography variant="h6" sx={{m:2, pt: 2}} color='primary'>メンバー</Typography>
                <List>
                    {users.map((u: ModelUser) => (
                        <Box>
                            <ListItem key={u.uid} sx={{px: 3, borderTop: '1px solid lightgray'}}>
                                <ColoredAvatar name={u.name} email={u.email}></ColoredAvatar>
                                <ListItemText sx={{ml: 2}} primary={`${u.name} <${u.email}>`} />
                                <Tooltip title='削除' >
                                    <IconButton onClick={() => {handleDeleteUser(u)}}>
                                        <DeleteIcon></DeleteIcon>
                                    </IconButton>
                                </Tooltip>
                            </ListItem>
                        </Box>
                ))}
                </List>
                {(users.length <= 0) && <Typography sx={{px: 3, pb: 3}} align='center'>(メンバーがまだ登録されていません)</Typography>}
            </Paper>

            <DeleteComfirmDialog 
                open={openDeleteConfirmDialog}
                onClose={handleDeleteComfirmDialogClose}
                onDeleteUser={onDeleteUser} 
                user={deletingUser}
                />

        </Fragment>
    )
}

export default AssetGroupUsers;
