
import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore , connectFirestoreEmulator} from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDJwuv697MB84K-ZmN08dlJDrgNT1z9zJw",
    authDomain: "group-reservation-sys.firebaseapp.com",
    projectId: "group-reservation-sys",
    storageBucket: "group-reservation-sys.appspot.com",
    messagingSenderId: "673154047851",
    appId: "1:673154047851:web:527323764aecd62efff62e"
  };

export const firebaseApp = initializeApp(firebaseConfig);

export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);

const location: Location = window.location;

// for emulation 
if (location.hostname === 'localhost') {
    connectAuthEmulator(auth, "http://localhost:9099");
    connectFirestoreEmulator(db, 'localhost', 8080);
}
