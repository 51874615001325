import { useState, useRef } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, TextField} from'@mui/material'
import { Tooltip, Box, Typography, IconButton, Button } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';

type EditableAssetGroupTitleProps = {
    onEdit: (newTitle: string) => void,
    title: string | null,
  }
  
  const EditableAssetGroupTitle = (props: EditableAssetGroupTitleProps) => {
  
    const [open, setOpen] = useState<boolean>(false)
    const title = useRef<string>('')
  
    const openEditDialog = () => {
      title.current = props.title ? props.title : ''
      setOpen(true)
    }
  
    const handleClose = () => {setOpen(false)}
  
    const handleEdit = () => {
      setOpen(false)
      title.current && props.onEdit(title.current)
    }
  
    return (
  
      <Box>
        <Typography variant="h4" gutterBottom sx={{mt: 5}}> 
          {props.title} 
          <Tooltip title='変更' placement="right">
            <IconButton onClick={openEditDialog}><EditIcon fontSize="small"/></IconButton>
          </Tooltip>
        </Typography>
  
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>施設の名前の変更</DialogTitle>
          <DialogContent sx={{minWidth: 360}}>
            <DialogContentText sx={{mb: 3}}>
              新しい名前を入力してください〜！
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              type="text"
              fullWidth
              variant="filled"
              defaultValue={title.current}
              onChange={(e) => {title.current = e.target.value}}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEdit}>変更</Button>
            <Button onClick={handleClose}>キャンセル</Button>
          </DialogActions>
        </Dialog>
      
      </Box>
    )
  }


export default EditableAssetGroupTitle;
