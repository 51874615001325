import React, { useEffect, useState, useContext } from 'react';
import { Typography, Button, Box, Grid, Modal} from '@mui/material';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Dayjs } from 'dayjs';
import 'dayjs/locale/ja'

import { Model_createReservation } from '../db/dbModels';
import { AuthContext } from '../authRouting/authProvider';
import { useYymmOnCal } from './groupCalendarContext';
import { YyMm } from './calView/calViewDefs'


const modealStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
}

const CreateReservation = () => {
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [canReserv, setCanReserv] = useState<Boolean>(false);
  const {currentUserInfo, currentUser} = useContext(AuthContext)

  const {notifyYymmOnCal} = useYymmOnCal()
  const [resvError, setResvError] = useState<{open: boolean, message: string}>({open: false, message:''})

  useEffect(() => {
    if (currentUser) {
      setCanReserv(true)
    }

  }, [currentUserInfo, currentUser])

  const handleReservation = async () => {
    if (!startDate || !endDate) {
      setResvError({open: true, message: '日付を入力してください'})
      return;
    }

    if (!currentUserInfo) {
      return;
    }

    try {
      await Model_createReservation(
        currentUserInfo.uid,
        currentUserInfo.assetGroupId,
        startDate, endDate)
    } catch(e) {
      if (e instanceof Error)
        setResvError({open: true, message: e.message})
      else 
        setResvError({open: true, message: `Internal error: ${e}`})
    }

    // 予約後の処理などを記述する場合はここに追加
    notifyYymmOnCal(new YyMm(startDate.year(), startDate.month()))
  };

  const handleModalErrorClose = () => {
    setResvError({open: false, message: ''})
  }

  return (
      <Box>



        {/** Error message on Modal is here! */}
        <Modal
          open={resvError.open}
          onClose={handleModalErrorClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modealStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              予約の作成
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {resvError.message}
            </Typography>
          </Box>
        </Modal>

        {/** Main reservation element is here! */}
        <Box>
          <Typography component="h2" variant="h6" color="primary" gutterBottom>予約の作成</Typography>
        </Box>
        <Box sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>

          {/* 
          <Typography variant="h6" sx={{ mt: 3, mb: 2 }}> 予約者: { currentUser?.displayName } </Typography>
        */}

          <Box>
            <Grid container spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ja'>
                <Grid item>
                  <DatePicker
                    label="開始日"
                    value={startDate}
                    onChange={(date) => {setStartDate(date); date && setEndDate(date.add(1, "day"))}} 
                  />
                </Grid>
                <Grid item>
                  <DatePicker
                    label="終了日"
                    value={endDate}
                    onChange={(date) => setEndDate(date)} 
                  />
                </Grid>
              </LocalizationProvider>
            </Grid>

            <Button variant="contained" sx={{ mt: 3, mb: 2 }} fullWidth onClick={handleReservation} disabled={!canReserv}>
              予約
            </Button>
          </Box>
        </Box>
      </Box>
  );
};

export default CreateReservation;
