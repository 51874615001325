import { useEffect, useState } from "react";
import { Box, TableContainer, Table, TableBody, TableRow, TableCell, Container, Paper, Link, Typography } from "@mui/material";
import { ModelUser, Model_getAssetGroup } from "../db/dbModels";

export type ProfileTableProps = {
    userInfo: ModelUser | null,
    adminMode: boolean,
};

const ProfileTable = ({ userInfo, adminMode }: ProfileTableProps) => {
    const [assetName, setAssetName] = useState<string>('')

    useEffect(() => {
        const fetch = async () => {
            if (userInfo && userInfo.assetGroupId !== '') {
                const assetGroup = await Model_getAssetGroup(userInfo.assetGroupId)
                if (assetGroup) {
                    setAssetName(assetGroup.name)
                } else {
                    setAssetName('')
                }
            } else {
                setAssetName('')
            }
        };

        fetch();
    }, [userInfo])

    const formatDate = (d: Date | undefined): string => {
        if (!d) return " ";
        return `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日`;
    }
    return (
        <Box>
            <Paper sx={{ mt: 6 }}>

                <TableContainer >
                    <Table sx={{ minWidth: 650 }}>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'primary,' }}>名前</TableCell><TableCell>{userInfo?.name}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ color: 'primary,' }}>メール</TableCell><TableCell>{userInfo?.email}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ color: 'primary,' }}>ユーザID</TableCell><TableCell>{userInfo?.uid}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ color: 'primary,' }}>利用施設</TableCell>
                                {
                                    (assetName !== '') ?
                                        (<TableCell><Link href='/groupCalendar'>{assetName}</Link></TableCell>) :
                                        (<TableCell sx={{ color: 'red' }}>(設定待ち)</TableCell>)
                                }
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <Paper sx={{ mt: 2 }}>
                <TableContainer >
                    <Table sx={{ minWidth: 650 }}>
                        <TableBody>

                            {(userInfo && userInfo.agreementDate) ? (
                                <TableRow>
                                    <TableCell sx={{ color: 'primary,' }}>契約日</TableCell>
                                    <TableCell>{formatDate(userInfo?.agreementDate?.toDate())}</TableCell>
                                </TableRow>
                            ) : (<></>)}

                            {userInfo && userInfo.personalInfo ?
                                (
                                    <>
                                        <TableRow>
                                            <TableCell sx={{ color: 'primary,' }}>契約者名</TableCell>
                                            <TableCell>{userInfo.personalInfo.fullName}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ color: 'primary,' }}>住所</TableCell>
                                            <TableCell>
                                                {`〒${userInfo.personalInfo.postalCode} : ${userInfo.personalInfo.address}`}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ color: 'primary,' }}>電話番号</TableCell>
                                            <TableCell>{userInfo.personalInfo.phoneNumber}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ color: 'primary,' }}>クルマ情報</TableCell>
                                            <TableCell>{userInfo.personalInfo.carInfo}</TableCell>
                                        </TableRow>
                                    </>
                                ) : (
                                    <>
                                        {!adminMode ? (
                                            <>
                                                <a href="/formPersonInfo"> 「ユーザ登録情報」画面</a>から必要な情報の入力をお願いします。
                                            </>
                                        ) : (
                                            <Typography>（ユーザ情報が登録されていない）</Typography>
                                        )}
                                    </>
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box >
    )
}

export default ProfileTable;

