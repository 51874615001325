import { doc, getDoc, runTransaction } from "firebase/firestore";
import { db } from "../firebase";

export type ConfigInDatabase = {
  systemMailAddress: string | Array<string>,
};

const defaultConfig: ConfigInDatabase = {
  systemMailAddress: "kazushi@studio-biju.com",
}

export const Config_getConfig = async (): Promise<ConfigInDatabase> => {
  const snapshot = await getDoc(doc(db, 'Config', "Default"));
  if (snapshot.exists()) {
    return Promise.resolve(snapshot.data() as ConfigInDatabase);
  }
  return Promise.resolve(defaultConfig);
}
