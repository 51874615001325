import React from "react"
import AppMenuBar from "../appMenuBar"
import { Container, Typography } from "@mui/material"
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

/**********************************************************************************
  グループIDがない、または、有効でない場合に表示する画面
***********************************************************************************/


const InvalidGroupId = () => {
    return (
      <React.Fragment>
        <AppMenuBar />
        <Container maxWidth="md">
          <Typography align='center' sx={{my: 5}}><ReportProblemIcon fontSize='large'/></Typography>
          <Typography variant="h4" align='center' gutterBottom sx={{my: 5}}>
            URLが有効な施設ではありません
          </Typography>
        </Container>
      </React.Fragment>
    )
  }

export default InvalidGroupId;