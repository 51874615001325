import { Box, Button, FormControl, Grid, TextField, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { ModePersonalInfo } from "../db/dbModels";

const isNumber = (val: any): boolean => {
  var regexp = new RegExp(/^[0-9]+(\.[0-9]+)?$/);
  return regexp.test(val);
}

const isValidEmail = (val: any): boolean => {
  var regexp = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/);
  return regexp.test(val);
}

const isValidPhoneNumber = (val: string): boolean => {
  const regexp = new RegExp(/^[0-9-]+$/);
  return regexp.test(val);
}

export type PersonalInfoProps = {
  onEnter: (data: ModePersonalInfo) => void,
  onEnterWithError: (errorMessage: string) => void,
}

const PersonInfo = (props: PersonalInfoProps) => {

  const [errFullName, setErrFullName] = useState<string | undefined>(undefined);
  const [errAddress, setErrAddress] = useState<string | undefined>(undefined);
  const [errPostalCode, setErrPostalCode] = useState<string | undefined>(undefined);
  const [errPhoneNumber, setErrPhoneNumber] = useState<string | undefined>(undefined);

  const [fullName, setFullName] = useState<string>("");
  const [postalCode, setPostalCode] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [carInfo, setCarInfo] = useState<string>("");

  const addressTextFieldRef = useRef<HTMLInputElement>(null);

  const onClickEnterButton = () => {
    let goNext : boolean = true;
    if (errFullName) {
      goNext = false;
    }
    
    if (fullName === "") {
      setErrFullName("名前を入力してください");
      goNext = false;
    }

    if (errAddress) {
      goNext = false;
    }

    if (address === "") {
      setErrAddress("住所を入力してください");
      goNext = false;
    }

    if (errPostalCode) {
      goNext = false;
    }
    if (postalCode === "") {
      setErrPostalCode("郵便番号を入力してください");
      goNext = false;
    }

    if (errPhoneNumber) {
      goNext = false;
    }

    if (phoneNumber === "") {
      setErrPhoneNumber("電話番号を入力してください");
      goNext = false;
    } 

    if (goNext) {
      props.onEnter({fullName, postalCode, address, phoneNumber, carInfo});
    } else {
      props.onEnterWithError("必要な情報を入力してください");
    }
  }

  const onUserFullNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrFullName(undefined);
    setFullName(event.target.value);
  }

  const searchAddressByPostalCode = async (postalCode: string) => {

    const addrJson = await (await fetch(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postalCode}`)).json();

    let addr = "";
    if (addrJson.status && addrJson.status == 200 && addrJson.results) {
      addr = addrJson.results[0].address1;
      addr += addrJson.results[0].address2;
      addr += addrJson.results[0].address3;
    }

    setAddress(addr);
    addressTextFieldRef.current?.focus();
  }

  const onPostalCodeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrPostalCode(undefined);

    const value = event.target.value;
    if (value != "" && !isNumber(value)) {
      setErrPostalCode("数字のみ入力してください");
      return;
    }

    if (7 == value.length) {
      setPostalCode(value);
      searchAddressByPostalCode(value);
    }
  }

  const onUserAddressChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrAddress(undefined);
    setAddress(event.target.value);
  }

  const onUserPhoneNumberChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrPhoneNumber(undefined);
    setPhoneNumber(event.target.value);
  }

  const onUserPhoneNumberBlurred = (event: React.FocusEvent<HTMLInputElement>) => {
    if (phoneNumber == "") {
      setErrPhoneNumber(undefined);
    } else if (!isValidPhoneNumber(phoneNumber)) {
      setErrPhoneNumber("電話番号は数字またはハイフンで入力してください");
    } else {
      setErrPhoneNumber(undefined);
    }
  }

  const onCarInfoChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCarInfo(event.target.value);
  }

  return (
    <>
      <Typography component="h4" variant="h4">ユーザー情報の入力</Typography>

      <Box component="form" sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth sx={{ mb: 3 }}>
              <TextField
                required
                id="user-full-name"
                label="契約者名"
                variant="outlined"
                onChange={onUserFullNameChanged}
                helperText={errFullName}
                error={errFullName ? true : undefined}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth sx={{ mb: 3 }}>
              <TextField
                required
                id="postal-code"
                label="郵便番号"
                variant="outlined"
                sx={{ width: '25ch', mb: 2 }}
                error={errPostalCode ? true : undefined}
                helperText={errPostalCode}
                onChange={onPostalCodeChanged}
              />

              <TextField
                required
                id="user-address"
                label="住所"
                variant="outlined"
                value={address}
                ref={addressTextFieldRef}
                onChange={onUserAddressChanged}
                helperText={errAddress}
                error={errAddress ? true : undefined}
              />

            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth sx={{ mb: 3 }}>
              <TextField
                required
                id="user-phone-number"
                label="電話番号"
                variant="outlined"
                value={phoneNumber}
                onChange={onUserPhoneNumberChanged}
                onBlur={onUserPhoneNumberBlurred}
                helperText={errPhoneNumber}
                error={errPhoneNumber ? true : undefined}
                type="tel"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth sx={{ mb: 3 }}>
              <TextField
                id="user-car-number"
                label="車の車種/ナンバー/色"
                variant="outlined"
                value={carInfo}
                onChange={onCarInfoChanged}
                helperText={"クルマの車種/ナンバー/色など、識別できる情報をお書きください"}
              />

            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained"
              fullWidth
              style={{ marginTop: "0.5em", marginBottom: "0.5em" }}
              onClick={onClickEnterButton}
            >
              登録
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default PersonInfo;
