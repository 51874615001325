
import { Box, List, ListItem, ListItemButton, Modal, Typography } from '@mui/material';
import { UserListData } from './groupCalendarContext';
import { Fragment, useState } from 'react';
import ReservationListForEachUser from './reservationListEachUser';


type UserReservationListProps = {
  uid: string,
  name: string,
  email: string,
  open: boolean,
  setOpen: (open: boolean) => void,
}

const UserReservationList = (props: UserReservationListProps) => {
  return (
    <Modal
      open={props.open}
      onClose={() => { props.setOpen(false) }}
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      sx={{
        display: 'flex',
        p: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: (theme) => theme.shadows[5],
          p: 4,
        }}
      >
        <ReservationListForEachUser uid={props.uid} username={props.name} email={props.email} />
      </Box>
    </Modal>
  )
}

type UserListProps = {
  userListData: UserListData[],
}

const GroupUserList = ({ userListData }: UserListProps) => {

  const [userResvList, setUserResvList] = useState<UserReservationListProps>({ uid: '', name: '', email: '', open: false, setOpen: () => { } })

  const setOpen = (open: boolean) => {
    setUserResvList((state) => { return { ...state, open: open } })
  }

  const handleClick = (data: UserListData) => {
    setUserResvList({
      uid: data.uid,
      name: data.name,
      email: data.email,
      open: true,
      setOpen: setOpen,
    })
  }

  return (
    <Fragment>
      <Typography sx={{ paddingX: "20px", py: "10px" }} component="h2" variant="h6" color="primary" gutterBottom>メンバー</Typography>
      <List>
        {userListData.map((user) => (
          <ListItem key={user.uid} >
            <ListItemButton key={user.name} sx={{
              fontSize: "0.8rem",
              fontWeight: 'small',
              lineHeight: '20px', px: 0
            }}
              onClick={() => { handleClick(user) }}>
              <div style={{ width: '20px', height: '13px', backgroundColor: user.bgColor, color: user.color, display: 'inline-block', marginRight: "6px", borderRadius: '5px' }}></div>
              {user.name}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <UserReservationList
        uid={userResvList.uid}
        name={userResvList.name}
        email={userResvList.email}
        open={userResvList.open}
        setOpen={setOpen} />
    </Fragment>
  );
};

export default GroupUserList;