import { useContext } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import { Dialog, Button, DialogContent, } from '@mui/material';
import {Box, Paper, Table, TableBody, TableHead, TableCell, TableRow} from '@mui/material';
import Typography from '@mui/material/Typography';
import { calViewResrvData, formatResvDate } from './calViewDefs';
import { AuthContext } from '../../authRouting/authProvider';

import { useYymmOnCal } from '../groupCalendarContext';
import ColoredAvatar from '../../userAuth/coloredAvator';
import { Model_cancelReservation } from '../../db/dbModels';


export interface CalViewResvDialogProps {
  open: boolean;
  selectedValue: string;
  resvData: calViewResrvData|null;
  onClose: (value: string) => void;
  adminMode: boolean,
}

const CalViewResvDialog = (props: CalViewResvDialogProps) => {
  const { onClose, selectedValue, open, resvData} = props;

  const { notifyYymmOnCal } = useYymmOnCal()
  const { currentUser } = useContext(AuthContext)

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleDeleteReservation = async () => {
    if (resvData) {
      try {
        await Model_cancelReservation(resvData.uid, resvData.assetGroupId, resvData.id, resvData.start, resvData.end);
        notifyYymmOnCal();
        handleClose();
      } catch (error) {
        console.error('予約の削除中にエラーが発生しました', error);
      }
    }
  }

  let startDayText = '';
  let endDayText = '';
  if (resvData) {
    startDayText = formatResvDate(resvData.start)
    endDayText = formatResvDate(resvData.end)
  }

  let reservedByOthers = true;
  if (resvData && currentUser) {
    reservedByOthers = (currentUser.uid !== resvData.uid)
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle color='primary'>予約内容</DialogTitle>
      <DialogContent>
          {!props.adminMode && reservedByOthers ? (
            <>
            <Typography align='center' sx={{mb: 2}}>他の会員による予約</Typography>
            </>
          ) : (
            <>
              <Box sx={{justifyContent:'center', display: 'flex', mb: 2}}>
                <ColoredAvatar name={resvData ? resvData.username : ''} email={resvData ? resvData.email : ''}></ColoredAvatar>
              </Box>
              <Typography align='center' sx={{mb: 2}}>予約者: {resvData?.username}</Typography>
            </>
          )}
            <Paper>
                <Table sx={{width: "500px"}}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" >開始日</TableCell>
                            <TableCell align="center" >終了日</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="center" >{startDayText}</TableCell>
                            <TableCell align="center" >{endDayText}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Paper>
      </DialogContent>

      {!reservedByOthers && 
        <Box sx={{justifyContent: 'center', display:'flex', mb:2}}>
          <Button disabled={reservedByOthers} variant='outlined' color='warning' onClick={() => handleDeleteReservation()}>予約の削除</Button>
        </Box>
      }

    </Dialog>
  );
}

export default CalViewResvDialog
