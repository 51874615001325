//import logo from './logo.svg';
import logo from './images/sofa_logo.png'
import './App.css';
import AppMenuBar from './appMenuBar';
import { Box, Button } from '@mui/material';

const Home = () => {

  return (
    <div className="App">
      <AppMenuBar></AppMenuBar>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p style={{paddingBottom: "2rem"}}>
          ハイドレンジア箱根に泊まって箱根を満喫しよう！
        </p>

        <Button variant='contained' href='/groupCalendar'>カレンダーへ</Button>

        <Box sx={{height: "3rem"}}></Box>
      </header>
  </div>
  );
};

export default Home;
