import React, { useState, useContext, useEffect } from 'react';
import { Container, TextField, Button, Typography, CssBaseline, Box, Grid, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, } from 'firebase/auth';
import { auth } from '../firebase';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../authRouting/authProvider';
import { CircularProgress } from '@mui/material';

function GoToHome(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      <Link color="inherit" href="/">
        トップページ
      </Link>{' '}
    </Typography>
  );
}

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [logining, setLoging] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const {currentUserInfo} = useContext(AuthContext)

  if (currentUserInfo && location.state && location.state.from) {
    navigate(location.state.from)
  }

  const handleLogin = async () => {
    try {
      setLoging(true)
      await signInWithEmailAndPassword(auth, email, password);
      // ログイン成功時の処理
      console.log('ログイン成功');
    } catch (error: any) {
      // ログイン失敗時の処理
      console.log('ログイン失敗', error);
      setErrorMessage(error.message);
      console.error('エラーコード:', error.code);
      console.error('エラーメッセージ:', error.message);
      setLoging(false)
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
        
        
        <Typography component="h4" variant="h4">ログイン</Typography>


        {errorMessage && <Grid item xs={12} sm={6}><Typography color="error">{errorMessage}</Typography></Grid>}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              style={{ marginTop: "0.5em", marginBottom: "0.5em" }}
              type="email"
              label="メールアドレス"
              value={email}
              fullWidth
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
            style={{ marginTop: "0.5em", marginBottom: "0.5em" }}
            type="password"
            label="パスワード"
            value={password}
            fullWidth
            onChange={(e) => setPassword(e.target.value)}
          />
          </Grid>
        </Grid>


        <Button variant="contained" 
            fullWidth
            sx={{ mt: 3, mb: 2 }}
            onClick={handleLogin} >
              ログイン
          {logining &&  <CircularProgress sx={{color:'white'}}/> }
        </Button>

        <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/createUser" variant="body2">
                  ユーザ登録はこちらからどうぞ
                </Link>
              </Grid>
        </Grid>

      </Box>
      <GoToHome  sx={{ mt: 5 }}></GoToHome>
    </Container>
  );
};

export default Login;
