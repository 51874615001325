

import { 
    Box,
    ImageList, ImageListItem } from '@mui/material';
import { eyeCatchPics } from "../common/eyeCatchPics";

type AssetEyeCatchesProps = {
    onClick: (url : string) => void,
}

const AssetEyeCatches = (props: AssetEyeCatchesProps) => {
    return (
        <Box>
            <ImageList sx={{ width: 500, height: 300 }} cols={2} rowHeight={160}>
            {eyeCatchPics.map((item) => (
                <ImageListItem key={item.roomNum} onClick={() => {props.onClick(item.url)}} sx={{cursor: 'pointer'}}>
                        <img
                            src={`${item.url}?w=164&h=164&fit=crop&auto=format`}
                            srcSet={`${item.url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                            alt={'' + item.roomNum}
                            loading="lazy"
                        />
                </ImageListItem>
            ))}
            </ImageList>
        </Box>
        
    )
}

export default AssetEyeCatches;
