
import dayjs, { Dayjs } from "dayjs";
import { ModelDataId, ModelUser, Model_getAssetGroup, Model_getUser } from "../db/dbModels";
import { addDoc, collection, } from "firebase/firestore";
import { db } from '../firebase';
import { Config_getConfig } from "../db/dbConfig";




const MailTempl_CreateAccount_Subect = "[予約] アカウント作成 ${username} ";
const MailTempl_CreateAccount_Text = "予約システムからのメールです。\n\nアカウントが作成されました。\n\nメール: ${email}  \n\n名前: ${username}  \n\nユーザID: ${uid}";

const MailTempl_CreateReservation_Subject = "[予約] ${username} が予約を作成（${start} - ${end})";
const MailTampl_CreateReservation_Text = "予約システムからのメールです。\n\n会員名: ${username}<${email}> さんが予約を作成しました。\n予約期間は、${start} から ${end} です。\n部屋は、${groupname} です。";

const MailTempl_CancelReservation_Subject = "[予約] ${username} が予約をキャンセル（${start} - ${end})";
const MailTampl_CancelReservation_Text = "予約システムからのメールです。\n\n会員名: ${username}<${email}> さんが予約をキャンセルしました。\nキャンセルした予約の期間は、${start} から ${end} です。\n部屋は、${groupname} です。";


export type MailEntry = {
  uid: ModelDataId,
  to: string | Array<string>,
  message: {
    subject: string,
    text: string,
  }
}

type MailTemplateReplacer = Record<string, string>;


const _getMailToAddress = async () : Promise<string | Array<string>> => {
  const config = await Config_getConfig();
  return config.systemMailAddress;
}

const _processTemplate = (template: string, replacer: MailTemplateReplacer): string => {

  let ret = template;

  for (let [key, value] of Object.entries(replacer)) {
    ret = ret.replaceAll("${" + key + "}", value);
  }

  return ret;
}

export const Mail_createUser = async (uid: ModelDataId, user: ModelUser) => {

  const username = (user && user.name) ? user.name : "(unknown user)";
  const email = (user && user.email) ? user.email : "(unknown)";

  const entry: MailEntry = {
    uid,
    to: await _getMailToAddress(),
    message: {
      subject: _processTemplate(MailTempl_CreateAccount_Subect, { username, email, uid }),
      text: _processTemplate(MailTempl_CreateAccount_Text, { username, email, uid }),
    }
  };

  await addDoc(collection(db, 'mail'), entry);
  return Promise.resolve(true);
}


export const Mail_createReservation = async (uid: ModelDataId, groupId: ModelDataId,
  startDate: Dayjs, endDate: Dayjs): Promise<boolean> => {

  const user = await Model_getUser(uid);
  const group = await Model_getAssetGroup(groupId);


  const username = (user && user.name) ? user.name : "(unknown user)";
  const email = (user && user.email) ? user.email : "(unknown email)";
  const groupname = (group && group.name) ? group.name : "(unknown group)";
  const start = startDate.format("YYYY/MM/DD");
  const end = endDate.format("YYYY/ MM/DD");

  const entry: MailEntry = {
    uid,
    to: await _getMailToAddress(),
    message: {
      subject: _processTemplate(MailTempl_CreateReservation_Subject, { username, email, groupname, start, end }),
      text: _processTemplate(MailTampl_CreateReservation_Text, { username, email, groupname, start, end }),
    }
  }

  await addDoc(collection(db, 'mail'), entry);
  return Promise.resolve(true);
}


export const Mail_cancelReservation = async (uid: ModelDataId, groupId: ModelDataId, 
  startDate: Dayjs, endDate: Dayjs): Promise<boolean> => {

  const user = await Model_getUser(uid);
  const group = await Model_getAssetGroup(groupId);

  const username = (user && user.name) ? user.name : "(unknown user)";
  const email = (user && user.email) ? user.email : "(unknown email)";
  const groupname = (group && group.name) ? group.name : "(unknown group)";
  const start = startDate.format("YYYY/MM/DD");
  const end = endDate.format("YYYY/ MM/DD");

  const entry: MailEntry = {
    uid,
    to: await _getMailToAddress(),
    message: {
      subject: _processTemplate(MailTempl_CancelReservation_Subject, { username, email, groupname, start, end }),
      text: _processTemplate(MailTampl_CancelReservation_Text, { username, email, groupname, start, end }),
    }
  }

  await addDoc(collection(db, 'mail'), entry);
  return Promise.resolve(true);
}
