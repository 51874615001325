
type HolidayData = {
    date: Date,
    name: string,
}
  
export class Holidays {

    private holidays: Array<HolidayData>;

    private constructor(data: Record<string, string>) {
        this.holidays = Object.entries(data).map(([dateString, name]) => ({
          date: new Date(dateString),
          name,
        }));

        /*
        this.holidays.forEach((e) => {
            console.log('------ ' + e.date.getFullYear() + '/' + (e.date.getMonth() + 1) + '/' + (e.date.getDate() + ': ' + e.name))
        })
        */
    }

    getHolidayString(date: Date): string {
        
        const holiday = this.holidays.find(h => 
            ((h.date.getDate() === date.getDate()) && (h.date.getFullYear() === date.getFullYear()) && (h.date.getMonth() === date.getMonth())));

        return holiday ? holiday.name : '';
    }

    static async fetch(): Promise<Holidays> {
        try {
            const response = await fetch('https://holidays-jp.github.io/api/v1/date.json');
            const data = await response.json();
            return new Holidays(data);
        } catch (error) {
            throw new Error('データの取得に失敗しました。');
        }
    }
}

// 実行例
/*
Holidays.fetch()
.then(holidays => {
    const currentDate = new Date(); // 現在の日付を使用する場合
    const holidayInfo = holidays.getHolidayInfo(currentDate);
    console.log(holidayInfo);
})
.catch(error => console.error(error));
*/
