import { Fragment } from "react";
import AppMenuBar from "../appMenuBar";
import { Container, Typography } from "@mui/material";

import MessageIcon from '@mui/icons-material/Message';

const AssetNotAssigned = () => {
    return (
        <Fragment>
        <AppMenuBar></AppMenuBar>
        <Container maxWidth="md">
          <Typography align='center' sx={{m: 8}}>
              <MessageIcon fontSize='large' />
          </Typography>
          <Typography align='center' variant='h5' gutterBottom>
            オーナーが許可を行うまで予約表は使えません
          </Typography>
          <Typography align='center' gutterBottom sx={{m: 2}}>
          設定に数日かかる場合があります。しばらくお待ち下さい。<br/ >
          一週間以上待っても使えない場合は、お手数ですがオーナーまでご連絡ください。
          </Typography>
        </Container>
      </Fragment>
    )
}

export default AssetNotAssigned;
