import { Box, Paper, Typography } from "@mui/material";
import { ModelDataId, ModelUser, Model_getAssetGroup } from "../db/dbModels"
import GroupUserList from "./groupUserList";
import { Fragment, useContext, useEffect, useState } from "react";
import { useGroupUsers, UserListData } from "./groupCalendarContext";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { stringToColor3 } from "../userAuth/coloredAvator";
import { AuthContext } from "../authRouting/authProvider";
  
type AssetGroupInfoProps = {
    groupId: ModelDataId,
    adminMode?: boolean,
}

const AssetGroupInfo = (props: AssetGroupInfoProps) => {
    const [ eyeCatchUrl, setEyeCatchUrl] = useState<string>("")
    const { groupUsers, notifyGroupUsersUpdated } = useGroupUsers()

    const {currentUserInfo} = useContext(AuthContext)
  
    useEffect(() => {
      
      const fetch = async () => {

          const selfUid = currentUserInfo ? currentUserInfo.uid : '';

          if (props.groupId) {
              const usersRef = collection(db, 'Users');
              const queryRef = query(usersRef, where('assetGroupId', '==', props.groupId));
  
              // クエリを実行して結果を取得する
              const querySnapshot = await getDocs(queryRef);
  
              // 結果の処理
              const fetchedGroupUsers: UserListData[] = []
              querySnapshot.forEach((doc) => {
                  // doc.data() でドキュメントのデータを取得できます
                  const user = doc.data() as ModelUser
                  console.log(user);
  
                if (selfUid === user.uid) {
                    fetchedGroupUsers.unshift(
                                        {name: user.name ? user.name: '', uid: user.uid, 
                                        email: user.email ? user.email : '',
                                        bgColor: stringToColor3(user.email ? user.email : ''), color: 'white'});
                } else if (props.adminMode) {
                    fetchedGroupUsers.unshift(
                        {name: user.name ? user.name : '', uid: user.uid, 
                        email: user.email ? user.email : '',
                        bgColor: stringToColor3(user.email ? user.email : ''), color: 'white'}) // no color aplpha for all users
                } else {    // admin modeではなく、他のユーザの予定である。
                    fetchedGroupUsers.push(
                        {name: user.name ? user.name : '', uid: user.uid, 
                        email: user.email ? user.email : '',
                        bgColor: "#10101030", color: 'white'}) // color given 'alpha' as opacity
                }
              });
              notifyGroupUsersUpdated(fetchedGroupUsers)

              // asset group data for Eye catch Url
              const assetGroup = await Model_getAssetGroup(props.groupId)
              if (assetGroup && assetGroup.eyeCatchUrl) {
                setEyeCatchUrl(assetGroup.eyeCatchUrl)
              }
            }
          }
  
          fetch()
  
      }, [props.groupId, currentUserInfo, notifyGroupUsersUpdated, props.adminMode]);  

    return (
        <Fragment>
            {props.adminMode ? (
                <>
                    <Paper sx={{height: '30%', display:'flex', alignItems:'center', justifyContent: 'center', backgroundImage:`url(${eyeCatchUrl})`}} >
                    {(eyeCatchUrl && eyeCatchUrl !== '') ? 
                        (
                        /*<Card><CardMedia sx={{height: 120, width: 240}} image={eyeCatchUrl}></CardMedia></Card>*/
                        <></>
                        ) : (
                        <Typography color='gray'>NO IMAGE</Typography>
                        )
                    }
                    </Paper>

                    <Box sx={{height: '1%'}} />
                    <Paper sx={{height: '69%'}}>
                        <GroupUserList userListData={groupUsers} />
                    </Paper>
        </>) : (<></>)}

        </Fragment>
    )
}

export default AssetGroupInfo;