import React from 'react';
import { createContext, useState, useEffect } from 'react';
import { Unsubscribe, User, onAuthStateChanged } from 'firebase/auth'
import { auth } from '../firebase';
import { ModelUser, Model_getUser, Model_subscribeUser } from '../db/dbModels';

export enum AuthLoginState {
  Waiting, NotAuthAuthorized, Authorized,
}

type AuthContextType = {
  authState: AuthLoginState,
  currentUser: User | null,
  currentUserInfo: ModelUser | null,
}

type AuthProviderProps = {
  children: React.ReactNode
}

export const AuthContext = createContext<AuthContextType>({ authState: AuthLoginState.Waiting, currentUser: null, currentUserInfo: null });

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [authState, setAuthLoginState] = useState<AuthLoginState>(AuthLoginState.Waiting);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [currentUserInfo, setCurrentUserInfo] = useState<ModelUser | null> (null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user: User | null) => {

      console.log('#### onAuthStateChanged + ' + (user ? user.uid : '(invalid uid)'))

      if (user) {
        try {
          const userData = await Model_getUser(user.uid);
          if (userData) {
            console.log('#### setAuthInfo is going to be called with Authorized.')
            setAuthLoginState(AuthLoginState.Authorized);
            setCurrentUser(user);
          }
        } catch (error) {
          console.log('causing error while getting user data (firestore)' + error)
        }
      } else {
        console.log('#### setAuthInfo is going to be called with NotAuthAuthorized.');
          setAuthLoginState(AuthLoginState.NotAuthAuthorized);
          setCurrentUser(null);
      }
    });

    return () => {
      console.log('### AuthProvider: going to unsubscribe onAuthStateChanged!?')
      unsubscribe();
    }
  }, []);

  useEffect(() => {
    console.log("getUser Data in Auth Provider: " + (currentUser ? currentUser.uid : "none"));
    let unsubscribe : Unsubscribe | null = null;
    if (currentUser) {
      unsubscribe = Model_subscribeUser(currentUser && currentUser.uid, (user: ModelUser) => {
        console.log("setCurrentUserInfo" + user);
        setCurrentUserInfo(user);
      });
    } else {
      setCurrentUserInfo(null); 
    }

    return () => {
      console.log("#### AuthProvider going to subscribe UserData Change:" + unsubscribe ? "yes" : "no");
      if (unsubscribe) unsubscribe();
    }
  }, [currentUser]);

  return (
    <AuthContext.Provider value={{ authState, currentUser, currentUserInfo }}>
      {children}
    </AuthContext.Provider>
  );
}

// const useAuth = () => useContext(AuthContext);

export default AuthProvider;
