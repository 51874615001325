import React, { Fragment, useContext, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext, AuthLoginState } from './authProvider';
import { Container, Typography } from '@mui/material';
import AppMenuBar from '../appMenuBar';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';

type AdminRouteProps = {
  element: React.ReactElement
}

const AdminRoute: React.FC<AdminRouteProps> = ({ element }) => {
  const { authState,  currentUserInfo} = useContext(AuthContext)
  const location = useLocation();

  useEffect(() => {

  }, [authState])

  if (authState === AuthLoginState.Waiting) {
    console.log('[AdminRoute] is rendering AuthLoginState.Waiting.')
    return (
      <Typography>Waiting...</Typography>
    )
  } else if (authState === AuthLoginState.NotAuthAuthorized) {
    console.log('[AdminRoute] is rendering AuthLoginState.NotAuthAuthorized. location.pathname=' + location.pathname)
    return (
      <Navigate to="/login" state={{from: location.pathname}} />
    )
  } else if (currentUserInfo && !currentUserInfo.isAdministrator) {
    console.log('[AdminRoute] is rendering NOT isAdministrator.')
    return (
      <NoAdminPrivalege />
    )
  } else {
    console.log('[AdminRoute] is rendering normally')
    return element
  }
}

const NoAdminPrivalege = () => {
  return (
    <Fragment>
      <AppMenuBar />
      <Container sx={{mt: 7}}>
          <Typography align="center" sx={{mb: 3}}>
              <PermIdentityIcon fontSize="large"/>
          </Typography>
          <Typography align='center'>
              管理者ユーザのみ使用可能な画面です
          </Typography>
      </Container>
    </Fragment>
  )
}

export default AdminRoute;