import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, } from 'react-router-dom';
import { Container, Select, MenuItem, Button, Box, IconButton, Divider, DialogContent, DialogActions, InputLabel, FormControl, } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import { ModelAssetGroup, ModelDataId, ModelUser, Model_assignUserToAssetGroup, Model_deleteAssetGroup, Model_modifyAssetGroupName, Model_removeUserFromAssetGroup, Model_subscribeUnassignedUserList } from '../db/dbModels';
import AssetGroupUsers from './assetGroupUsers';
import EditableAssetGroupTitle from './editableAssetGroupTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import { Dialog, DialogTitle } from '@mui/material';
import UpdateEyeCatchDialog from './updateEyeCatchDialog';
import InvalidGroupId from './invalidGroupId';
import EyeCatchPicture from './eyeCatchPicture';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

/**********************************************************************************
  施設を削除する確認ダイアログ
***********************************************************************************/

type DeleteAssetGroupConfirmDialogProps = {
  open: boolean,
  onClose: (doDelete: boolean) => void,
}

const DeleteAssetGroupConfirmDialog = (props: DeleteAssetGroupConfirmDialogProps) => {

  return (
    <Dialog 
      open={props.open}
      onClose={props.onClose}>
        <DialogTitle>施設の削除</DialogTitle>
        <DialogContent sx={{width: 600}}>
          本当に、この施設を削除しますか？
        </DialogContent>
        <DialogActions>
          <Button color="warning" onClick={() => props.onClose(true)}>削除</Button>
          <Button autoFocus onClick={() => props.onClose(false)}>キャンセル</Button>
        </DialogActions>
    </Dialog>
  )
}



/**********************************************************************************
  未登録ユーザ候補の一覧と登録
***********************************************************************************/

type SelectUserToAsignProp = {
  groupId: ModelDataId
}

const SelectUserToAsign = (props : SelectUserToAsignProp) => {
  
  const [users, setUsers] = useState<ModelUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<string>('');
  
  useEffect(() => {

    const unsubscribe = Model_subscribeUnassignedUserList((unsignedUsers) => {
      setSelectedUser('')
      setUsers(unsignedUsers)
    })

    return () => unsubscribe()

  }, []);

  const handleUserAdd = async () => {
    if (!props.groupId || selectedUser === '') {
      return;
    }
    await Model_assignUserToAssetGroup(props.groupId, selectedUser)
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px'}}>
      <FormControl>
        <InputLabel id="select-user-to-assign-label">未登録のユーザ</InputLabel>
        <Select
          labelId='select-user-to-assign-label' 
          label='未登録のユーザ' 
          value={selectedUser} onChange={(e) => setSelectedUser(e.target.value as string)} sx={{width: '40ch'}}>
        {users.map((user) => (
          <MenuItem key={user.uid} value={user.uid}>
            {user.name}
          </MenuItem>
        ))}
        </Select>
      </FormControl>

      <Button variant="outlined" onClick={handleUserAdd} startIcon={<ArrowDownwardIcon />}>
        このユーザをメンバーに追加
      </Button>
    </Box>
  )
}

/**********************************************************************************
  メイン画面
***********************************************************************************/
const AssetGroup = () => {
  const { groupId } = useParams<{ groupId?: string }>();
  const [assetGroup, setAssetGroup] = useState<{valid: boolean, id: string, data?: ModelAssetGroup}>({valid: false, id: ''});

  const navigate = useNavigate()

  useEffect(() => {
    if (!groupId) {
      return;
    }

    const unsubscribe = onSnapshot(doc(db, 'AssetGroups', groupId), (snapshot) => {
      if (snapshot.exists()) {
        setAssetGroup({ valid: true, id: snapshot.id, data: snapshot.data() as ModelAssetGroup});
      } else {
        setAssetGroup({valid: false, id: ''});
      }
    });

    return () => unsubscribe();
  }, [groupId]);

  const onDeleteUser = (uid: ModelDataId) : void => {
    if (!groupId || !assetGroup.valid) {
      return;
    }

    Model_removeUserFromAssetGroup(groupId, uid)
  }

  const onEditTitle = (newTitle: string) : void => {
    if (!groupId) {
      return;
    }

    Model_modifyAssetGroupName(groupId, newTitle)
  }
  
  const [openDeleteAssetGroupConfrimDlg, doDialogAssetGroupConfimDlg] = useState<boolean>(false)
  const onDeleteGroupAsset = (doDelete: boolean) => {
    doDialogAssetGroupConfimDlg(false)
    if (doDelete && groupId) {
      Model_deleteAssetGroup(groupId)
      navigate('/createAssetGroup')
    }
  }

  const [openEditEyeCatchDialog, doEditEyeCatchDialog] = useState<boolean>(false)
  const onCloseEditeEyeCatchDialog = () => {
    doEditEyeCatchDialog(false)
  }

  if (!groupId || !assetGroup.valid) {
    return (
      <InvalidGroupId />
    );
  }

  return (
    <Container maxWidth="md">
      <Box sx={{my: 2}}>
        <IconButton onClick={() => {navigate('/createAssetGroup')}}>
          <ArrowBackIcon />
        </IconButton>
      </Box>
      
      <EyeCatchPicture
          url={(assetGroup.data && "eyeCatchUrl" in assetGroup.data) ? assetGroup.data.eyeCatchUrl : undefined}
          overlappedText={assetGroup.data && assetGroup.data.name}
          onEdit={() => doEditEyeCatchDialog(true)} />

      <UpdateEyeCatchDialog
          open={openEditEyeCatchDialog}
          groupId={groupId}
          url={(assetGroup.data && "eyeCatchUrl" in assetGroup.data) ? assetGroup.data.eyeCatchUrl : undefined}
          onClose={onCloseEditeEyeCatchDialog}
          ></UpdateEyeCatchDialog> 
      

      <EditableAssetGroupTitle title={assetGroup.data ? assetGroup.data.name : 'Loading...'} onEdit={onEditTitle}></EditableAssetGroupTitle>
      
      <Box sx={{mb: 1, height: 10}} />
      <SelectUserToAsign groupId={groupId} />

      <AssetGroupUsers userids={assetGroup.data && assetGroup.data.users ? assetGroup.data.users : []} onDeleteUser={onDeleteUser}></AssetGroupUsers>


      <Divider sx={{m:3}} />
      
      <Box sx={{textAlign: 'center'}}>
          <Button 
            variant="outlined" color='warning' 
            sx={{fontSize:'small'}} 
            startIcon={<DeleteIcon/>}
            onClick={() => doDialogAssetGroupConfimDlg(true)}
          >この施設の削除</Button>

          <DeleteAssetGroupConfirmDialog
            open={openDeleteAssetGroupConfrimDlg}
            onClose={onDeleteGroupAsset} />
      </Box>

    </Container>
  );
};

export default AssetGroup;
