import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// import { doc, setDoc } from 'firebase/firestore';
import { applyActionCode, getAuth } from 'firebase/auth';

import { firebaseApp, } from '../firebase';
const auth = getAuth(firebaseApp)


const EmailVerified: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const oobCode = queryParams.get('oobCode');

  useEffect(() => {
    const verifyEmail = async () => {
      if (oobCode) {
        try {
          // メールの確認を行う
          await applyActionCode(auth, oobCode);

          // Firestoreにユーザデータを保存する
          /* ここでやるのをヤメて、createUserした直後に登録することにする
          const user = auth.currentUser;
          if (user) {
            const userData = {
              name: user.displayName,
              email: user.email,
              uid: user.uid,
              isAdministrator: false, // 初期値としてfalseを設定
            };
            await setDoc(doc(db, 'Users', user.uid), userData);
          }
          */
        } catch (error) {
          console.log('メールの確認に失敗しました', error);
        }
      }
    };

    verifyEmail();
  }, [oobCode]);

  return (
    <div>
      <h2>Email Verification</h2>
      <p>メールの確認が完了しました。</p>
      <p>ユーザデータの保存が完了しました。</p>
    </div>
  );
};

export default EmailVerified;
