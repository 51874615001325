
import { 
    List, ListItemButton, ListItemText,
    Box, Typography, Button,
    Card, CardMedia, CardContent, AvatarGroup, ListItem, IconButton, 
    Menu, MenuItem, ListItemIcon} from "@mui/material";
import { AssetGroup } from "./assetGroupDefs";
import { useLocation, useNavigate } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { Model_getAssetGroup, Model_getUser } from "../db/dbModels";
import { ModelUser } from "../db/dbModels";
import ColoredAvatar from "../userAuth/coloredAvator";
import { Link } from "react-router-dom";
import { Link as MuiLink} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
  

/***************************************************************************/
// AssetGroupAvatars: リスト上に表示されるグループのアバターたち　                 
/***************************************************************************/

type AssetGroupAvatarsProp = {
    groupId: string
}
const AssetGroupAvatars = ({groupId} : AssetGroupAvatarsProp) => {
    const location = useLocation();
    const [users, setUsers] = useState<ModelUser[]>([])

    useEffect(() => {

        const fetch = async () => {
            // グループに属する全てのユーザの名前から、Avatarを生成するためのデータを作る
            const modelAssetGroup = await Model_getAssetGroup(groupId)
            if (modelAssetGroup) {
                const arrayOfUser : ModelUser[] = []
                for (const u of modelAssetGroup.users) {
                    let modelUser = await Model_getUser(u)
                    if (modelUser) {
                        arrayOfUser.push(modelUser)
                    }
                }
                setUsers(arrayOfUser)
                console.log('avatars : length=' + arrayOfUser.length)
            }
        }

        fetch()
    }, [groupId])

    if (users.length <= 0) 
    {
        return (
          <Box sx={{mt: 3}}>
            <Button component={Link} to={`/assetGroup/${groupId}`} state={{from: location.pathname}} variant="outlined">
              <Typography  variant="subtitle1" color="text.secondary">施設にメンバーを割り当て</Typography>
            </Button>
          </Box> 
        )
    }

    return (
        <AvatarGroup max={3} sx={{mt: 3, display: 'inline-flex'}}>
            {
                users.map((u: ModelUser) => (
                    /* <Avatar alt={u.name ? u.name: ''}  src='/#' ></Avatar> */
                    <ColoredAvatar name={u.name} email={u.email}></ColoredAvatar>
                ))
            }
        </AvatarGroup>
    )
}

/***************************************************************************/
// MoreVertMenu: リスト項目（施設）に対するメニュー
/***************************************************************************/
type MoreVertMenuProp = {
  groupId: string,
}

const MoreVertMenu = (props: MoreVertMenuProp) => {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Fragment>

        <IconButton sx={{position:'absolute', top: 10, right: 20}}
                  id="more-vert-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  >
                    <MoreVertIcon />
        </IconButton>

        <Menu
        id="more-vert-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'more-vert-button',
        }}
        >
          <MenuItem onClick={handleClose} component={Link} to={`/adminGroupCalendar/${props.groupId}`} state={{from: location.pathname}}>
            <ListItemIcon><EventSeatIcon fontSize="small" /></ListItemIcon>
            予約一覧
          </MenuItem>
          <MenuItem onClick={handleClose} component={Link} to={`/assetGroup/${props.groupId}`} state={{from: location.pathname}}>
          <ListItemIcon><ManageAccountsIcon fontSize="small" /></ListItemIcon>
            施設の編集
          </MenuItem>
        </Menu>

    </Fragment>
  )
}

/***************************************************************************/
// AssetGroupList: リストの本体
/***************************************************************************/

type AssetGroupListProp = {
  assetGroups : AssetGroup[]
}

const AssetGroupList = ({assetGroups} : AssetGroupListProp) => {
    const navigate = useNavigate()
    const location = useLocation();

    const handleAssetGroupClick = (groupId: string) => {
        navigate(`/adminGroupCalendar/${groupId}`, {state: {from: location.pathname}});
    };

    return (
        <Box sx={{ marginTop: 4, padding: '16px' }}>

        <Typography component="h2" variant="h6" color="primary" gutterBottom>登録済み施設</Typography>
          <List>
            {assetGroups.length > 0 ? (
              assetGroups.map((group: AssetGroup) => (
                <ListItem key={group.id} sx={{position:'relative'}} >
                  <Card sx={{width: '100%', display: 'flex'}}>
                    
                    <CardMedia content='img' sx={{width: 240, height: 160, position: "relative", cursor: 'pointer'}} 
                      image={group.url} 
                      onClick={() => handleAssetGroupClick(group.id)}/>

                    <CardContent>
                      <MuiLink component={Link} to={`/adminGroupCalendar/${group.id}`} state={{from: location.pathname}}>
                        <Typography variant="h5" >{group.name}</Typography>
                      </MuiLink>
                      <AssetGroupAvatars groupId={group.id}></AssetGroupAvatars>
                    </CardContent>

                  </Card>

                  <MoreVertMenu groupId={group.id}></MoreVertMenu>
                  
                  {/* <ListItemText primary={group.name} /> */}
                </ListItem>
              ))
            ) : (
              <ListItemButton>
                <ListItemText primary="登録済み施設がありません" />
              </ListItemButton>
            )}
          </List>
        </Box>
    )
}

export default AssetGroupList;
