import React, { useContext, useEffect, useState } from 'react';
import { Container, Paper, Box, Grid, } from '@mui/material';

import { AuthContext } from '../authRouting/authProvider';
import CreateReservation from './createReservation';
import AssetGroupInfo from './assetGroupInfo';
import CalendarView from './calView/calView';
import GroupTitle from './groupTitle';

import { GroupCalendarContextProvider } from './groupCalendarContext';
import AppMenuBar from '../appMenuBar';
import AssetNotAssiged from './assetNotAssigned';
import EyeCatchPicture from '../assetGroup/eyeCatchPicture';
import { ModelAssetGroup } from '../db/dbModels';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import ReservationListView from '../assetGroup/reservationListView';
import PersonInfoNotStored from './personInfoNotStored';

const GroupCalendar = () => {
  console.log(`[GroupCalendar] is rendering!`)
  const {currentUserInfo} = useContext(AuthContext)
  const groupId = currentUserInfo ? currentUserInfo.assetGroupId : ''
  const [assetGroup, setAssetGroup] = useState<{valid: boolean, id: string, data?: ModelAssetGroup}>({valid: false, id: ''});

  useEffect(() => {
    if (!groupId) {
      return;
    }

    const unsubscribe = onSnapshot(doc(db, 'AssetGroups', groupId), (snapshot) => {
      if (snapshot.exists()) {
        setAssetGroup({ valid: true, id: snapshot.id, data: snapshot.data() as ModelAssetGroup});
      } else {
        setAssetGroup({valid: false, id: ''});
      }
    });

    return () => unsubscribe();
  }, [groupId]);

  if (!currentUserInfo || (currentUserInfo && !currentUserInfo.personalInfo)) {
    return (
      <PersonInfoNotStored />
    );
  }

  if (!groupId) {
    return (
      <AssetNotAssiged />
    );
  }

  return (
    <Box>
      
      <AppMenuBar></AppMenuBar>

      <Container maxWidth="xl">

        {/* アイキャッチ */}
        <EyeCatchPicture
          url={(assetGroup.data && "eyeCatchUrl" in assetGroup.data) ? assetGroup.data.eyeCatchUrl : undefined}
          overlappedText={assetGroup.data ? assetGroup.data.name : undefined} />

        <GroupCalendarContextProvider>
          <Paper sx={{paddingX: "20px", paddingY: "10px", marginY: "5px"}}>
            <CreateReservation></CreateReservation>
          </Paper>
          
          <Paper sx={{paddingX: "20px", paddingY: "10px", marginY: "5px"}}>
            <CalendarView groupId={groupId}></CalendarView> 
          </Paper>

           {/**  no view for user mode, however process is needed here. */}
          <AssetGroupInfo groupId={groupId}></AssetGroupInfo> 
          <ReservationListView currentUserId={currentUserInfo ? currentUserInfo.uid : ""} />
        </GroupCalendarContextProvider>

        <p></p>

      </Container>
    </Box>
    
  );
};

export default GroupCalendar;
